@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/Thin/Roboto-Thin.ttf")  ;
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/ThinItalic/Roboto-ThinItalic.ttf")  ;
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/Light/Roboto-Light.ttf")  ;
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/LightItalic/Roboto-LightItalic.ttf")  ;
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/Regular/Roboto-Regular.ttf")  ;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/Regular/Roboto-Regular.ttf")  ;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/Italic/Roboto-Italic.ttf")  ;
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/Italic/Roboto-Italic.ttf") ;
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/Medium/Roboto-Medium.ttf")  ;
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/MediumItalic/Roboto-MediumItalic.ttf")  ;
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/Bold/Roboto-Bold.ttf")  ;
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: RobotoB;
    src: url("../fonts/roboto/Bold/Roboto-Bold.ttf")  ;

}

@font-face {
    font-family: RobotoL;
    src: url("../fonts/roboto/Light/Roboto-Light.ttf") ;

}

@font-face {
    font-family: RobotoT;
    src: url("../fonts/roboto/Thin/Roboto-Thin.ttf")  ;

}

@font-face {
    font-family: RobotoM;
    src: url("../fonts/roboto/Medium/Roboto-Medium.ttf")  ;

}

@font-face {
    font-family: RobotoBold;
    src: url("../fonts/roboto/Bold/Roboto-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/Bold/Roboto-Bold.ttf")  ;
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/BoldItalic/Roboto-BoldItalic.ttf")  ;
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/BoldItalic/Roboto-BoldItalic.ttf") ;
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/Black/Roboto-Black.ttf")  ;
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url("../fonts/roboto/BlackItalic/Roboto-BlackItalic.ttf")  ;
    font-weight: 900;
    font-style: italic;
}

body {
    background-color: #fff;
    color: #2d3055;
    font-family: Roboto;
    font-size: 12px;
}

/*
 */
body.filter_fix,
body.city_parallax_fix,
body.route_parallax_fix,
body.search_parallax_fix {
    padding-top: 55px;
}

.state h6 {
    font-size: 1rem;
    line-height: 0.8
}

.form-group:last-child {
    margin-bottom: 0
}

.form-control {
    font-size: 13px;
    line-height: 35px;
    padding: 0 10px;
    border-radius: 0;
    border-width: 0 0 1px;
}

.form-control:focus {
    box-shadow: none;
    border-color: #33cccc;
}

.btn,
a.btn {
    padding: 0 15px;
    line-height: 40px;
    border-radius: 4px;
    background: #33cccc;
    color: #fff;
}

.btn:hover,
a.btn:hover {
    background: #33cccc;
    color: #fff;
}

.btn i {
    float: left;
    line-height: inherit;
    margin-right: 10px;
    font-size: 18px;
}

.btn-full {
    width: 100%;
}

a:hover, a:focus {
    text-decoration: none;
}

a:hover {
    color: #33cccc
}

ul, ol {
    padding: 0;
    margin: 0
}

ul li {
    list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #33CCCC
}

.select2-dropdown {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
    border: none;
}

.tooltip {
    font-family: Roboto;

}

.tooltip .tooltip-inner {
    background-color: #87A2AF
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
    border-top-color: #87A2AF
}

.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip.bs-tooltip-right .arrow::before {
    border-right-color: #87A2AF
}

.tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.tooltip.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #87A2AF
}

.tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before,
.tooltip.bs-tooltip-left .arrow::before {
    border-left-color: #87A2AF
}

#mobile_menu {
    position: fixed;
    right: -220px;
    top: 0;
    bottom: 0;
    width: 220px;
    background: #fff;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    transition: 0.4s;
    padding: 30px 10px 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

div.slider-inner, div.slider-inner > *, div.slider-inner .slick-list, div.slider-inner .slick-track, div.slider-inner .slick-slide > * {
    height: 100% !important
}

#mobile_menu.open {
    right: 0;
    visibility: visible;
    opacity: 1
}

#mobile_menu ul li a {
    display: block;
    color: #222;
    font-size: 13px;
    padding: 0 10px;
    line-height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

#mobile_menu ul li:last-child a {
    border: none;
}

#mobile_menu ul li a i {
    float: right;
    line-height: inherit;
    width: 25px;
    margin-right: 5px;
    font-size: 20px;
}

#mobile_menu .mobile_menu_logo img {
    display: block;
    margin: 0 auto;
    max-width: 100px;
}

#close_menu {
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 20px;
    color: #212121;
    cursor: pointer;
}

#search_wrapper .search_form input, .btn-search {
    font-size: var(--font-size2)
}

#close_menu i {
    display: block;
    line-height: 30px;
    width: 30px;
    text-align: center;
}

#search_wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    /* background: rgba(0, 0, 0, 0.85); */
    display: none;
    /* cursor: url(../images/cursor_close.png) 15 15, pointer; */
}

.search__inner {
    -webkit-box-shadow: -1px 2px 12px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 2px 12px -4px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 2px 12px -4px rgba(0, 0, 0, 0.75);
    background-color: #fff;
    position: relative;
    overflow: auto;
    max-height: calc(100vh - 100px);
    min-height: 200px
}

#search_wrapper .search_form {
    position: relative;

    /* top: 50%;
    margin-top: -35px;
    height: 61px;  */

    background: #fff;
    /* border-radius: 10px; */
    /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
    margin-top: 61px;
    margin-left: 200px;
    margin-right: 200px;
    z-index: 5;
}

#search_wrapper .search_form form {
    border: 1px solid var(--IR-blue-color);
}

#search_wrapper .search_form .search_result {
    left: 0;
    right: 0;
    top: 100%
}

#search_wrapper .search_form input {
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    line-height: 60px;
    font-size: 18px;
    padding: 0 59px;
}

.btn-search {
    position: absolute;
    right: 11px;
    top: 9px;
    width: 152px;
    border-radius: 0 !important;
    background-color: var(--IR-blue-color) !important
}

#search_wrapper .search_form input:hover,
#search_wrapper .search_form input:focus {
    outline: none
}

#search_wrapper .search_form input:focus {
    border-color: #25c1c5;
}

#search_wrapper .search_form button {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 30px;
    color: var(--IR-blue-color);
    background: transparent;
    border: none;
    border-radius: 100%;
    left: 5px;
    top: -1px;
    position: absolute;
    cursor: pointer;
    z-index: 2;
    padding: 0
}

#search_wrapper .search_form button:hover {

    cursor: pointer;
}

#search_wrapper .search_form button:focus {
    outline: none
}

#search_wrapper .search_form button i {
    line-height: inherit;
    display: block;
}

#close_search {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 50px;
    height: 50px;
    font-size: 25px;
    color: #eee;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
}

#close_search i {
    display: block;
    line-height: inherit;
    color: var(--IR-blue-color)
}

header {

    background: #F5FFFF;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

header .container {
    position: relative;
}

header .container:after {
    content: '';
    display: table;
    clear: both;
}

header nav {
    float: left;
    position: relative;
}

header nav > ul:after {
    clear: both;
    content: '';
    display: table;
}

header nav > ul > li {
    float: left;
    margin-right: 25px;
    position: relative;
}

header nav > ul > li:last-child {
    margin-right: 0
}

header nav > ul > li > a {
    line-height: 60px;
    display: block;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #2d3055
}

header nav > ul > li > ul {
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    min-width: 180px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    display: none;
    z-index: 5;
}

header nav > ul > li:hover > ul {
    display: block;
}

header nav > ul > li > ul > li {
    position: relative;
}

header nav > ul > li > ul > li > a {
    display: block;
    padding: 0 15px;
    line-height: 40px;
    color: #222;
}

header nav > ul > li > ul > li:hover > a {
    background: #f5f5f5
}

header .logo {
    position: absolute;
    left: 50%;
    top: -10px;
    width: 80px;
    height: 80px;
    margin-left: -40px;
}

.logoMain {
    position: absolute;
    left: 10%;
    width: 80px;
    height: 55px;
    margin-left: -40px;
}

header .logo img {
    width: 100%;
    height: 100%;
    display: block;
}

.logoMain img {
    height: 100%;
    display: block;
}

header .header_right_menu {
    float: right;
    position: relative;
    z-index: 11;
}

header .header_right_menu ul li {
    float: right;
    margin-left: 4px;
}

header .header_right_menu ul li a {
    display: block;
    line-height: 30px;
    font-size: 13px;
    color: #2d3055;
    border-radius: 3px;
}

header .header_right_menu ul li:hover > a {
    background: #d3efef
}

header .header_right_menu ul li a.btn_style {
    background: #33cccc;
    color: #fff;
    padding: 0 15px;
    border-radius: 3px;
}

header .header_right_menu ul li a.btn_style:hover {
    background: #33cccc;
    color: #fff
}

header .header_right_menu ul li a i {
    width: 30px;
    display: block;
    line-height: inherit;
    text-align: center;
    font-size: 20px;
}

header .header_right_menu ul li.menu_toggle {
    display: none
}

header .header_right_menu ul li.lang_dropdown {
    position: relative;
}

header .header_right_menu ul li.lang_dropdown > a {
    padding: 0 5px;
}

header .header_right_menu ul li.lang_dropdown ul {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    background: #fff;
    z-index: 10;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)
}

header .header_right_menu ul li.lang_dropdown:hover ul {
    display: block;
}

header .header_right_menu ul li.lang_dropdown ul li {
    position: relative;
    float: none;
    margin: 0;
}

header .header_right_menu ul li.lang_dropdown ul li a {
    display: block;
    line-height: 30px;
    padding: 0 10px 0 45px;
    position: relative;
    color: #333
}

header .header_right_menu ul li.lang_dropdown ul li a:hover {
    background: #f5f5f5
}

header .header_right_menu ul li.lang_dropdown ul li a img {
    position: absolute;
    left: 10px;
    top: 6px;
}

#animate_box {
    position: relative;
    height: 550px;
}

#animate_layers {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    background-size: cover;
    background-position: center;
    background-color: #F5FFFF;
    overflow: hidden;
}

#header-box {
    height: calc(100vh - 137px);
    position: relative;
    min-height: 700px !important
}

#header-slider {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    min-height: 500px;
    bottom: 0;
    z-index: 2;
    background-size: cover;
    background-position: center;
    /*background-color: #F5FFFF;*/
    /* overflow: hidden; */
}

.layer {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
}

.layer#animate_layer_1 {
    background-image: url('../images/animate/1.png')
}

.layer#animate_layer_2 {
    background-image: url('../images/animate/2.png')
}

.layer#animate_layer_3 {
    background-image: url('../images/animate/3.png')
}

.layer#animate_layer_4 {
    background-image: url('../images/animate/4.png')
}

.animate_box_title {
    position: absolute;
    bottom: 345px;
    z-index: 10;
    color: #fff;
    text-align: center;
    left: 0;
    right: 0;
}

.animate_box_title h1, .animate_box_title h2 {
    line-height: 40px;

    display: block;
    padding: 0 15px;
    position: relative;
    color: #fff;
    text-shadow: 0 0px 15px #000000;
}

.animate_box_title h1 {
    font-size: 45px;
}

.animate_box_title h2 {
    font-size: 31px;
    font-family: 'RobotoL';
}

.search_result {

    left: 250px;
    right: 250px;

}

.search_result_items:after {
    clear: both;
    content: '';
    display: table;
}

.search_result_items {
    margin: 0
}

.search_result_item_col_4 {
    float: left;
    width: 25%;
    padding: 5px;
}

.search_result_item_col_3 {
    float: left;
    width: 33.33333%;
    padding: 5px;
}

.search_result_item_col_2 {
    float: left;
    width: 50%;
    padding: 5px;
}

.search_result_item {
    padding: 5px 5px 5px 70px;
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    min-height: 70px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    color: #303030;
    transition: all 1s;
}

.search_result_item:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.search_result_image {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 70px;
}

.search_result_image img {
    width: 100%;
    height: 100%;
    display: block;
}

.search_result {
    position: relative;

    z-index: 1000;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    background: #fff;
    color: #000;
    /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
    /* max-height: 400px; */
    /* overflow-y: auto; */
    /* border: 1px solid #bcbcbc; */
    /* display: none */
}

.search_result_content {
    padding-left: 10px;
}

.search_result_content span {
    display: block;
}

.search_result_content span:first-of-type {
    line-height: 20px;
    font-size: 14px;
    height: 40px;
    overflow: hidden;
}

.search_result_content span:last-of-type {
    line-height: 20px;
    color: rgba(1, 1, 1, 0.5)
}

.search_result_content span:last-of-type i {
    float: left;
    line-height: inherit;
    margin-right: 5px;
}

.search_result_content a {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5
}

.search_result_title {
    margin-top: 10px;
    font-size: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

#icon_info_box {
    padding: 17px 0;
    background: #f6f6f6 !important;
    position: relative;
}

.icon_info_item {
    text-align: center;
}

.icon_info_item img {
    display: block;
    margin: 0 auto 20px;
}

.icon_info_item h3 {
    line-height: 30px;
    font-size: 20px;
    margin-bottom: 15px;
}

.icon_info_item p {
    font-weight: 400;
    line-height: 20px;
    padding: 0 10px;
    font-size: 14px;
}

.sec_title {
    text-align: center;
    margin-bottom: 50px;
}

.passenger {
    border: 1px solid #c6c6c6;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.sec_title h2, .sec_title h1, .sec_title b {
    line-height: 30px;
    font-size: 25px;
    display: inline-block;
    padding: 0 15px;
    position: relative;
    color: #333;
}

.sec_title h3 {
    line-height: 30px;
    font-size: 25px;
    display: inline-block;
    padding: 0 15px;
    position: relative;
    color: #333;
}

.sec_title h3:before, .sec_title h1:before, .sec_title b:before {
    content: '';
    position: absolute;
    right: -40px;
    left: 100%;
    height: 2px;
    top: 50%;
    background: #Ff9900;
    box-shadow: 5px 0 0 0 #fff, 20px 0 0 0 #Ff9900, 25px 0 0 0 #fff, 30px 0 0 0 #Ff9900;
}

.sec_title h3:after, .sec_title h1:after, .sec_title b:after {
    content: '';
    position: absolute;
    left: -40px;
    right: 100%;
    height: 2px;
    top: 50%;
    background: #Ff9900;
    box-shadow: -5px 0 0 0 #fff, -20px 0 0 0 #Ff9900, -25px 0 0 0 #fff, -30px 0 0 0 #Ff9900;
}

.sec_title h3:after, .sec_title h1:after, .sec_title b:after {
    content: '';
    position: absolute;
    left: -40px;
    right: 100%;
    height: 2px;
    top: 50%;
    background: #Ff9900;
    box-shadow: -5px 0 0 0 #fff, -20px 0 0 0 #Ff9900, -25px 0 0 0 #fff, -30px 0 0 0 #Ff9900;
}

.sec_title h2:before {
    content: '';
    position: absolute;
    right: -40px;
    left: 100%;
    height: 2px;
    top: 50%;
    background: #Ff9900;
    box-shadow: 5px 0 0 0 #fff, 20px 0 0 0 #Ff9900, 25px 0 0 0 #fff, 30px 0 0 0 #Ff9900;
}

.sec_title h2:after {
    content: '';
    position: absolute;
    left: -40px;
    right: 100%;
    height: 2px;
    top: 50%;
    background: #Ff9900;
    box-shadow: -5px 0 0 0 #fff, -20px 0 0 0 #Ff9900, -25px 0 0 0 #fff, -30px 0 0 0 #Ff9900;
}

.itemName {
    line-height: 20px;

    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-family: RobotoL;
}

.bus_itemName {
    font-size: 1rem;
    white-space: nowrap;
    margin-top: 10px;
    color: #ff9900;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.itemPrice {
    line-height: 20px;
    margin-top: 5px;
    font-size: .9rem;
    color: #464646;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.top-cities {
    width: 100%;
    padding: 10px;
}

.city-name {
    width: 100%;
    bottom: 0;

    position: absolute;
    padding: 10px;
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.0) 100%);
    background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.0) 100%);
    background: -ms-linear-gradient(bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.0) 100%);
    background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.0) 100%);
    background: linear-gradient(bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.0) 100%);

}

.top-cities .top-city {
    width: 100%;
    height: 250px;
    border-radius: 5px;
    background-position: center;
    position: relative;
    color: #fff
}

.grid-city-item .top-city {
    width: 100%;
    border-radius: 5px;
    background-position: center;
    position: relative;
    color: #fff;
    height: 100%;

}

.double-city-item {
    width: 100%;
    display: flex;
    margin-top: 35px;
}

.grid-city-item {
    height: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.city-grid .left {
    width: 50%;
    height: 785px;
    float: left;
    margin-right: 15px
}

.double-city-item .left {
    width: 50%;
    height: 250px;
    float: left;
}

.city-grid .right {
    width: 50%;
    height: 785px;
    float: right;
    margin-left: 15px;
    display: flex;
}

.double-city-item .right {
    width: 50%;
    height: 250px;
    float: right;
    display: block;
}

.city-grid {
    display: flex !important;
}

@media screen and (max-width: 800px) {
    .city-grid {
        display: none !important;
    }

    .world-map-hide {
        display: none !important;
    }

    .bus-tickets-container {
        margin-top: 18rem !important;
    }

    .sec_title h2, .sec_title h1, .sec_title b {
        font-size: 15px !important;
    }

    .itemName {
        font-size: 15px !important;
    }

    .home-page-title .itemLink {
        font-size: 10px !important;
    }

    .top-cities .city-name b {
        font-size: 10px !important;
    }

    .sights_home_item_image {
        height: 190px !important;

    }

    .top-cities .top-city {
        height: 190px !important;

    }

}

@media screen and (max-width: 480px) {
    .logoMain {
        display: none !important;
    }

    .animate_box_title h1 {
        font-size: 20px !important;
    }

    .animate_box_title h2 {
        font-size: 17px !important;
    }

    .slider_title b {
        font-size: 15px !important;
    }

    .slider_title {
        max-width: 440px !important;
    }

    .slider_title h2 {
        font-size: 12px !important;
    }
}

.search_result_title_no_found {
    text-align: center;
    margin: 20px 0;
}

.search-view-more {
    float: right;
    font-size: .8rem;
    padding-right: 5px;
}

.search_result_title_no_found span {

    font-size: 1.2rem;
    color: #656565;
    font-family: RobotoB;
    margin: 20px 0;
}

.city-grid .left .big {
    height: 500px;

}

.city-grid .right .big {
    height: 500px;
    width: 100%;
    margin-top: 35px;

}

.city-grid .small {
    height: 250px;
    width: 100%;

}

.city-grid .rightsmall {
    height: 250px;
    width: 100%;
    margin-top: 35px;

}

.city-grid h1 {
    font-size: 1rem;
}

.city-grid span {
    float: right;
    font-size: .7rem;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px;
}

.city-grid .rightbig {
    height: 500px;
    width: 100%;

}

.city-grid .right .left {
    height: 100%;
    width: 50%;
    float: left;

}

.city-grid .right .right {
    height: 100%;
    width: 50%;
    float: right;
    display: block;
}

.overlay {
    opacity: 0;
    background: rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    transition: all .2s;
}

.overlay:hover {
    opacity: 1;

}

.top-cities .city-name h1 {
    font-size: 1.1rem;
}

.top-cities .city-name b {
    font-size: 1.1rem;
}

.sec_title h3 span {
    color: #d7941d
}

#routes_slider {
    padding: 40px 0
}

.routes_item {
    max-width: 210px;
    margin: 0 auto
}

.routes_item_image {
    height: 300px;
    margin-bottom: 5px;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
}

.routes_item_image:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    transition: 0.3s
}

.routes_item_image:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    transition: 0.3s
}

.routes_item_image:hover:after {
    opacity: 0
}

.routes_item_image:hover:before {
    opacity: 1
}

.routes_item_image a {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 4
}

.routes_item_image a span {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    line-height: 30px;
    padding: 0 10px;
    color: #fff;
    font-size: 14px;
    text-align: center;
}

.routes_item_rate {
    float: left;
    position: relative;
    width: 83px;
    height: 15px;
    margin-top: 5px;
}

.routes_item_rate_bg {
    position: absolute;
    left: 0;
    top: 0;
}

.routes_item_rate_ab {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
}

.routes_item_rate_ab_inner {
    width: 83px;
}

.routes_item_rate_ab_inner:after {
    clear: both;
    content: '';
    display: table;
}

.routes_item_rate .routes_item_circle {
    float: left;
    width: 15px;
    height: 15px;
    background: #ddd;
    border-radius: 100%;
    margin-right: 2px;
    position: relative;
}

.routes_item_rate .routes_item_circle:after {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    border: 2px solid #fff;
    top: 1px;
    left: 1px;
    border-radius: 100%;
}

.routes_item_rate .routes_item_circle:last-child {
    margin-right: 0
}

.routes_item_rate_ab .routes_item_circle {
    background: #ff9900;
}

.routes_item_review {
    float: left;
    margin-left: 5px;
    line-height: 25px;
    color: #333
}

.routes_item_review span {
    display: block;
}

.routes_item_favorite {
    float: right;
    width: 25px;
}

.routes_item_favorite a {
    display: block;
    line-height: 25px;
    text-align: center;
    color: #888
}

.routes_item_favorite a i {
    line-height: inherit;
    font-size: 18px;
    display: block;
}

#routes_slider .button-prev,
#routes_slider .button-next {
    height: 100%; /* custom added */
    position: absolute;
    left: 0;
    top: 0;
    bottom: 30px;
    width: 40px;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s
}

#routes_slider .button-next {
    left: auto;
    right: 0
}

#routes_slider .swiper-container:hover .button-prev,
#routes_slider .swiper-container:hover .button-next {
    opacity: 1;
    visibility: visible;
}

#routes_slider .button-prev i,
#routes_slider .button-next i {
    position: absolute;
    line-height: 40px;
    font-size: 30px;
    margin-top: -10px;
    top: 50%;
    left: 0;
    right: 0;
}

#sights_slider {
    padding: 40px 0
}

#sights_slider .swiper-slide {
    height: 420px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
}

#sights_slider .swiper-slide a {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 4;
    background: rgba(0, 0, 0, 0);
    transition: 0.3s
}

#sights_slider .swiper-slide a span {
    position: absolute;
    bottom: -50px;
    left: 15px;
    right: 15px;
    display: block;
    border-radius: 15px;
    background: #fff;
    line-height: 30px;
    padding: 0 10px;
    color: #222;
    font-size: 14px;
    text-align: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s
}

#sights_slider .swiper-slide:hover a {
    background: rgba(0, 0, 0, 0.4)
}

#sights_slider .swiper-slide:hover a span {
    bottom: 15px;
}

#sights_slider .button-prev,
#sights_slider .button-next {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s
}

#sights_slider .button-next {
    left: auto;
    right: 0
}

#sights_slider .swiper-container:hover .button-prev,
#sights_slider .swiper-container:hover .button-next {
    opacity: 1;
    visibility: visible;
}

#sights_slider .button-prev i,
#sights_slider .button-next i {
    position: absolute;
    line-height: 40px;
    font-size: 30px;
    margin-top: -10px;
    top: 50%;
    left: 0;
    right: 0;
}

#top_routes {
    padding: 40px 0
}

.top_rates_items:after {
    clear: both;
    content: '';
    display: table;
}

.top_rates_item {
    float: left;
    width: 25%;
    text-align: center;
    position: relative;
    padding-top: 80px;
}

.top_rates_item a > span:first-child span, .top_rates_item > span span {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    line-height: 30px;
    background: #d7941d;
    color: #fff;
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: 1;
    font-size: 12px;
}

.top_rates_item a > span:first-child, .top_rates_item > span {
    position: absolute;
    top: 10px;
    left: 50%;
    margin-left: -30px;
    border: 1px solid #d7941d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 100%;
    color: #d7941d;
    font-size: 30px;
    z-index: 2;
    background: #Fff;
    box-shadow: 0 0 0 2px #fff, 0 0 0 3px #ccc;
    transition: 0.3s
}

.top_rates_item:hover a > span:first-child {
    box-shadow: 0 0 0 4px #fff, 0 0 0 5px #ccc;
}

.top_rates_item a > p, .top_rates_item .dropdown-toggle {
    font-size: 14px;
    color: #666
}

.top_rates_item .dropdown-toggle i {
    font-size: 14px;
    color: #666;
    padding-left: 5px;
}

.dropdown-toggle:after {
    display: none;
}

#map_area {
    height: 500px;
}

footer {
    background: #333;
    border-top: 2px solid #Ff9900;
}

.top_footer {
    padding: 40px 0;
}

.footer_mod {
    margin-bottom: 20px;
}

.footer_mod_title {
    margin-bottom: 15px;
}

.footer_mod_title h3 {
    font-size: 20px;
    color: #fff;
}

.footer_mod_content ul li {
    position: relative;
}

.footer_mod_content ul li:before {
    width: 5px;
    height: 5px;
    background: #ffd26e;
    content: '';
    float: left;
    margin-top: 12.5px;
    margin-right: 7px;
    transform: rotate(-45deg);
    transition: 0.3s;
}

.footer_mod_content ul li:hover:before {
    transform: rotate(180deg);
    width: 7px;
    height: 1px;
    margin-right: 5px;
    margin-top: 15px;
}

.footer_mod_content ul li a {
    display: block;
    line-height: 30px;
    font-size: 14px;
    color: #f7f7f7
}

.footer_socials ul:after {
    clear: both;
    content: '';
    display: table;
}

.footer_socials ul li {
    float: left;
    margin-right: 10px;
}

.footer_socials ul li:before {
    display: none
}

.footer_socials ul li a {
    display: block;
    line-height: 20px;
    text-align: center;
    font-size: 25px;
    color: #999
}

.footer_socials ul li a i {
    line-height: inherit;
    display: block;
}

.footer_socials ul li:hover a i.fa-facebook {
    color: #3b5999
}

.footer_socials ul li:hover a i.fa-twitter {
    color: #55acee
}

.footer_socials ul li:hover a i.fa-google-plus {
    color: #4285f4
}

.footer_socials ul li:hover a i.fa-pinterest {
    color: #cd2023
}

.footer_socials ul li:hover a i.fa-instagram {
    color: #9b37bd
}

.cpright {
    border-top: 1px solid #fe9800;
    padding: 10px 0
}

.cpright:after {
    clear: both;
    content: '';
    display: table;
}

.cpright .footer_logo {
    float: left;
    margin-right: 10px;
    width: 50px;
    height: 50px;
}

.cpright p {
    float: left;
    margin: 15px 10px 0;
    line-height: 20px;
    color: #f2f2f2
}

.cpright ul {
    float: left;
    margin-top: 15px;
}

.cpright ul li {
    float: left;
    border-right: 1px solid #fe9801;
    padding: 0 10px;
}

.cpright ul li:last-child {
    border-right: none
}

.cpright ul li a {
    display: block;
    line-height: 20px;
    color: #f2f2f2
}

.section_box {
    padding: 30px 0
}

.sidebar_row {
    margin-bottom: 20px;
}

.sidebar_content ul li a {
    display: block;
    line-height: 30px;
    color: #444;
    font-size: 13px;
    border-left: 2px solid transparent;
    padding-left: 10px;
}

.sidebar_content ul li a:hover {
    color: #33cccc
}

.sidebar_content ul li.active a {
    border-color: #33cccc
}

.content_title {
    margin-bottom: 20px;
    text-align: center;
    padding: 20px 0
}

.content_title h1 {
    font-size: 25px;
    line-height: 40px;
}

.content_title h2 {
    font-size: 25px;
    line-height: 40px;
}

.content_title h3 {
    font-size: 18px;
    line-height: 40px;
    color: #2d3055
}

.inner_title {
    margin-bottom: 25px;
    border-bottom: 2px solid #e5e5e5
}

.inner_title h3, .inner_title h1, .inner_title h2 {
    font-size: 18px;
    line-height: 40px;
    display: inline-block;
    padding-right: 10px;
    border-bottom: 2px solid #33cccc;
    margin-bottom: -2px;
    color: #2d3055
}

.service_title {
    border: 1px solid #ff9900;
    background: #33cccc;
    border-radius: 3px;
    position: relative;
    margin-top: 130px;
    margin-bottom: 15px;
}

.service_title h3 {
    font-size: 18px;
    line-height: 38px;
    color: #fff;
    padding: 0 15px;
}

.service_title img {
    position: absolute;
    bottom: 100%;
    left: 20px;
}

.search_loading {
    position: absolute;
    right: 70px;
    top: 10px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border-width: 4px;
    border-color: #000 rgba(0, 0, 0, .3) rgba(0, 0, 0, .3);
    border-style: solid;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

.contentus_list ul li {
    padding-left: 30px;
    line-height: 30px;
    position: relative;
}

.contentus_list ul li i {
    position: absolute;
    left: 0;
    top: 0;
    line-height: inherit;
    width: 30px;
    height: 30px;
    font-size: 20px;
    text-align: center;
}

.contentus_list ul li > a,
.contentus_list ul li > span {
    display: block;
    line-height: 30px;
}

article {
    font-size: 14px;
    line-height: 28px;
    text-align: justify;
}

.service_list ul li {
    line-height: 40px;
    padding-left: 40px;
    position: relative;
}

.service_list ul li i {
    position: absolute;
    left: 0;
    top: 0;
    line-height: inherit;
    text-align: center;
    font-size: 20px;
}

.btn_center {
    text-align: center;
}

#page_header {
    background-color: #33CCCC;
    background-image: url('../images/pattern2.png');
    background-repeat: repeat;
    background-position: top center;
    color: #fff;
    position: relative;
    z-index: 2
}

#page_header .page_header {
    position: relative;
    padding: 20px 70px 20px 70px;
    text-align: center;
}

#page_header .page_header.travel_inspiration:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100px;
    background-image: url(../images/svg/4.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px;
}

#page_header .page_header.accommodations:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 75px;
    background-image: url(../images/svg/9.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75px;
}

#page_header .page_header.food_drinks:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 75px;
    background-image: url(../images/svg/10.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75px;
}

.fromCity::after {
    content: '';
    position: absolute;

    width: 110px;
    height: 110px;
    background-image: url(../../../assets/frontend2/images/svg/Green.png);

    background-repeat: no-repeat;
    background-position: center;
    background-size: 50px;
    margin-left: 30px;
    margin-top: -35px;
}

.airplane::before {
    content: '';
    position: absolute;

    width: 20px;
    height: 20px;
    background-image: url(../../../assets/frontend2/images/svg/airplane.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    margin-left: -25px;
    margin-top: 10px;
}

.taxi::before {
    content: '';
    position: absolute;

    width: 20px;
    height: 20px;
    background-image: url(../../../assets/frontend2/images/svg/taxi.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    margin-left: -25px;
    margin-top: 10px;
}

.bus::before {
    content: '';
    position: absolute;

    width: 20px;
    height: 20px;
    background-image: url(../../../assets/frontend2/images/svg/bus.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    margin-left: -25px;
    margin-top: 10px;
}

.train::before {
    content: '';
    position: absolute;

    width: 20px;
    height: 20px;
    background-image: url(../../../assets/frontend2/images/svg/train.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    margin-left: -25px;
    margin-top: 10px;
}

#page_header .page_header.rout_place:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100px;
    background-image: url(../images/svg/11.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px;
}

#page_header .page_header.sight_place:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 70px;
    background-image: url(../images/svg/12.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px;
}

#page_header .page_header.local_guide:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100px;
    background-image: url(../images/svg/13.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px;
}

#page_header .page_header:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 70px;
    height: 93px;
    background-image: url(../images/svg/7.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px;
}

#page_header h1 {
    font-size: 20px;
    display: inline-block;
    padding: 0 20px;
    line-height: 30px;
    background: #fff;
    border-radius: 15px;
    color: #33CCCC;
    margin-bottom: 30px;
}

#page_header h2 {
    position: relative;
    line-height: 30px;
    font-size: 25px;
    text-align: left;
}

.page_header_filter {
    text-align: left;
}

.page_header_filter:after {
    clear: both;
    content: '';
    display: table;
}

.page_header_filter_col {
    float: left;
}

.page_header_filter_col label {
    float: left;
    line-height: 30px;
    margin: 0 10px 0;
    font-size: 13px;
}

.page_header_filter_col .select2picker {
    float: left;
    min-width: 180px;
}

.page_header_filter_col .select2-selection,
.select2-container--default.select2-container--focus .select2-selection--multiple {
    line-height: 30px;
    height: 30px;
    border-radius: 15px;
    border: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    border: none;
    background: #249fc0;
    line-height: 22px;
    border-radius: 11px;
    font-weight: 400;
    font-size: 12px;
    padding: 0 8px;
}

.select2-selection:focus {
    outline: none
}

.select2-selection .select2-selection__rendered:focus {
    outline: none
}

.page_header_filter_col .select2-container--default.select2-container--open .select2-selection {
    border-radius: 15px;
}

.select2-container--default .select2-search--inline .select2-search__field {
    margin-top: 0
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: rgba(255, 255, 255, 0.6);
    margin-right: 4px;
}

.page_header_filter_col .select2-selection .select2-selection__rendered {
    line-height: 30px;
    padding: 0 15px 0 5px;
    font-weight: 500;
    text-align: center;
}

#page_filter {
    padding: 10px 0;
    background-color: #fff;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 1;
}

#page_filterSight {
    padding: 10px 0;
    background-color: #fff;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 1;
}

#page_filter.fixed {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
}

#page_filterSight.fixed {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
}

.filters:after {
    clear: both;
    content: '';
    display: table;
}

.filter_item {
    float: left;
    position: relative;
    margin-right: 10px;
}

.filter_item .filter_title {
    padding: 0 15px;
    line-height: 35px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 3px;
}

.filter_item .filter_title span span {
    font-size: 14px;
    background: #eee;
    line-height: 20px;
    padding: 0 10px;
    border-radius: 10px;
    color: #666;
    margin-top: 7.5px;
    display: inline-block;
    margin-left: 3px;
}

.filter_item .filter_title:hover {
    background-color: #d3efef
}

.filter_item.opened .filter_title {
    background-color: #33cccc;
    color: #fff
}

.filter_item .filter_title:active {
    transform: scale(0.9);
}

.filter_item .filter_title:after {
    float: right;
    margin-left: 5px;
    content: '\f2f9';
    font-family: material-design;
    line-height: inherit;
    font-size: 18px;
    transition: transform 0.3s
}

.filter_item.opened .filter_title:after {
    transform: rotate(180deg);
}

.filter_item .filter_content {
    position: absolute;
    left: 0;
    top: 55px;
    background: #fff;
    padding: 10px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
    width: 250px;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.5);
    transition: 0.3s;
    border-radius: 3px;
    color: #333;
    z-index: 9;
}

.filter_item .filter_content_box {
    max-height: 500px;
}

.filter_content_box .mCSB_scrollTools {
    width: 0px;
}

.filter_item .filter_content:before {
    content: '';
    position: absolute;
    left: 20px;
    top: -10px;
    border-bottom: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
}

.filter_item.opened .filter_content {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.filter_content_title {
    text-align: center;
    line-height: 30px;
    color: #2d3055;
    margin-bottom: 10px;
    border-bottom: 1px solid #33cccc;
    font-size: 14px;
    font-weight: 500
}

.ui_slider_title {
    margin-top: 5px;
    line-height: 15px;
}

.ui_slider.ui-widget.ui-widget-content {
    margin: 15px 6px;
    height: 12px;
    background: #fff;
    position: relative;
    border: 1px solid #333;
    border-radius: 6px;
}

.ui_slider.ui-slider .ui-slider-range {
    background: #33cccc;
    position: absolute;
    top: 0;
    bottom: 0
}

.ui_slider.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 12px;
    height: 20px;
    margin-left: -6px;
    top: -5px;
    cursor: default;
    -ms-touch-action: none;
    touch-action: none;
    border-radius: 2px;
    background: #fff;
    border: 1px solid #333;
    cursor: pointer;
}

.ui_slider.ui-slider .ui-slider-handle:focus,
.ui_slider.ui-slider .ui-slider-handle:active {
    outline: none
}

.price_range_value {
    line-height: 35px;
    font-size: 14px;
    color: #666;
    border-bottom: 1px solid #ddd
}

.price_range_value .dropdown {
    float: right;
}

.price_range_value .dropdown > a {
    padding: 0 10px;
    line-height: 20px;
    font-size: 11px;
    background-color: #999;
    color: #fff;
    display: inline-block;
    border-radius: 10px;
}

.price_range_value .dropdown-menu {
    padding: 0;
    width: 70px;
    min-width: 70px;
}

.price_range_value .dropdown-item {
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px;
}

.filter_content label {
    line-height: 20px;
    margin-bottom: 5px;
    display: block;
    font-size: 14px;
    font-weight: 500
}

.filter_row {
    border-bottom: 1px solid #33cccc;
    padding: 5px 0
}

.filter_row:after {
    clear: both;
    content: '';
    display: table;
}

.filter_row:last-child {
    border-bottom: none
}

.filter_row label {
    line-height: 40px;
    margin: 0
}

.filter_row a.clear_filter {
    background: #fff;
    display: block;
    line-height: 28px;
    color: #33cccc;
    border-radius: 15px;
    border: 1px solid #33cccc;
    text-align: center;
    font-size: 13px;
    margin-bottom: 10px;
    transition: 0.3s
}

.filter_row a.clear_filter:hover {
    background: #33cccc;
    color: #fff;
}

.filter_row .select2picker {
    width: 100%;
    margin-bottom: 10px;
}

.filter_row.filter_row_floated label {
    float: left
}

.filter_row .link_list li a {
    line-height: 30px;
    display: block;
    padding: 0 10px;
    font-size: 13px;
    color: #2d3055
}

.filter_row .link_list li a:hover {
    background-color: #eee
}

.filter_input_right {
    float: right;
    width: 150px;
}

.filter_input_right input {
    line-height: 39px;
}

.filter_row span.select2-container {
    margin-bottom: 10px;
    display: block;
}

.input-toggle {
    position: relative;
    float: right;
    vertical-align: middle;
    cursor: pointer;
    margin-top: 7px;
}

label.label-toggle {
    line-height: 40px;
    margin-bottom: 0;
    float: none
}

.input-toggle input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
}

.input-toggle-handle {
    display: block;
    width: 55px;
    height: 25px;
    transition: .4s ease;
    background-image: linear-gradient(#33cccc, #7de7ff);
    border-radius: 12.5px;
    box-shadow: inset 0 1px rgba(0, 0, 0, .15),
    inset 0 0 0 1px rgba(0, 0, 0, .15),
    inset 0 1px 2px rgba(0, 0, 0, .15),
    inset 44px 44px #fff;
}

.input-toggle-handle:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 2px;
    width: 21px;
    height: 21px;
    transition: .4s ease;
    border-radius: 14px;
    background: #fff;
    box-shadow: inset 0 -1px rgba(0, 0, 0, .2),
    inset 0 0 0 1px rgba(0, 0, 0, .15),
    0 1px 2px rgba(0, 0, 0, .1),
    0 6px 12px rgba(0, 0, 0, .1);
}

.input-toggle input:checked ~ .input-toggle-handle {
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.15), inset 0 0 0 1px #33cccc;
}

.input-toggle input:checked ~ .input-toggle-handle:before {
    left: 32px;
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #33cccc, 0 1px 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(0, 0, 0, 0.1);
}

.input-toggle:before {
    content: "on";
    left: 0;
    color: #fff;
}

.input-toggle:after {
    content: "off";
    right: 0;
    color: #1a626e;
}

.input-toggle:before,
.input-toggle:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 7px;
    font-size: 12px;
    z-index: 0;
}

.checkbox_custom {
    padding-left: 30px;
    position: relative;
}

.checkbox_custom span {
    font-size: 15px;
}

.checkbox_custom input[type="checkbox"] {
    display: none
}

.tick_exp {
    font-size: 20px;
    color: #33cccc;
    margin-right: 10px;
}

.checkbox_custom label {
    line-height: 40px;
    cursor: pointer;
}

.checkbox_custom label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 11px;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 1px solid #ccc;
}

.checkbox_custom input[type="checkbox"]:checked + label:before {
    border-color: #33cccc
}

.header-nav .nav-link {
    font-size: 22px;
    font-family: Roboto !important;
    color: #fff !important
}

.checkbox_custom input[type="checkbox"]:checked + label:after {
    position: absolute;
    left: 0;
    top: 10px;
    content: '\f26b';
    font-family: material-design;
    line-height: 18px;
    font-size: 18px;
    width: 18px;
    text-align: center;
    color: #33cccc
}

.filter_dropdown {
    position: relative;
    margin-bottom: 10px;
}

.filter_dropdown:last-child {
    margin-bottom: 0
}

.filter_dropdown .filter_dropdown_title label {
    cursor: pointer;
    padding: 0 10px;
    border-radius: 3px;
}

.filter_dropdown .filter_dropdown_title label:hover,
.filter_dropdown.opened .filter_dropdown_title label {
    background: #d3efef;
}

.filter_dropdown .filter_dropdown_title label:after {
    content: '\f2f9';
    float: right;
    font-family: material-design;
    line-height: inherit;
    font-size: 18px;
    color: #666;
    transition: 0.3s
}

.filter_dropdown.opened .filter_dropdown_title label:after {
    transform: rotate(180deg);
}

.filter_dropdown .filter_dropdown_content {
    display: none
}

.filter_dropdown.opened .filter_dropdown_content {
    display: block
}

#filter_toggle {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #ddd;
    background: #fff;
    z-index: 99;
    display: none
}

#filter_close {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #ddd;
    background: #fff;
    z-index: 9;
    display: none
}

#filter_toggle span,
#filter_close span {
    display: block;
    line-height: 49px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
}

#filter_toggle span i,
#filter_close span i {
    position: relative;
    top: 3px;
    line-height: 20px;
    margin-right: 5px;
    font-size: 18px;
}

.hotel_item {
    padding: 10px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    margin-bottom: 30px;
    position: relative;
    transition: 0.3s
}

.hotel_item:last-child {
    margin-bottom: 0
}

.hotel_item:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3)
}

.hotel_item:after {
    clear: both;
    content: '';
    display: table;
}

.hotel_item_image {
    float: left;
    width: 300px;
    height: 230px;
    margin-right: 10px;
    border-radius: 3px;
    overflow-y: hidden;
    position: relative;
}

.hotel_item_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hotel_item_btn {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -40px;
    transition: bottom 0.3s
}

.hotel_item:hover .hotel_item_btn {
    bottom: 0
}

.hotel_item_btn a {
    display: block;
    line-height: 40px;
    background: #33cccc;
    color: #fff;
    font-size: 16px;
    text-align: center;
    transition: background 0.3s
}

.hotel_item_btn a:hover {
    background: #7de7ff
}

.hotel_item_btn a i {
    display: inline-block;
    line-height: 20px;
    position: relative;
    top: 2px;
    font-size: 20px;
    margin-right: 10px
}

.hotel_item_review_box {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #eee;
    position: relative;
}

.hotel_item_review_box > span {
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -42.5px;
    width: 85px;
    padding: 0 5px;
    background: #fff;
    text-align: center;
    color: #000;
    line-height: 20px;
    font-size: 13px;
}

.hotel_item_review_box p {
    line-height: 18px;
    font-size: 13px;
    text-align: justify;
}

.hotel_item_destinations {
    padding-top: 15px;
    border-top: 1px solid #eee;
    position: relative;
    margin-top: 10px;
    margin-right: 10px;

}

.hotel_item_destinations ul li {

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hotel_item_destinations > span {
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -42.5px;
    width: 85px;
    padding: 0 5px;
    background: #fff;
    text-align: center;
    color: #000;
    line-height: 20px;
    font-size: 13px;
}

.hotel_item_destinations ul:after {
    clear: both;
    content: '';
    display: table;
}

.hotel_item_destinations ul li {
    float: left;
    width: 50%;
    line-height: 30px;
}

.hotel_item_destinations ul li:before {
    content: '';
    float: left;
    width: 15px;
    height: 15px;
    background: #7de7ff;
    border-radius: 100%;
    margin-top: 6.5px;
    margin-right: 5px;
    position: relative;
    border: 2px solid #249fc0;
    box-shadow: 0 0 0 2px #fff inset;
}

.hotel_item_time {
    padding-top: 25px;
    border-top: 1px solid #eee;
    right: 0;
    left: 0;
    margin-top: 40px;
    bottom: 0;
    position: relative;

}

.hotel_item_time > span {
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -60px;
    width: 120px;
    padding: 0 5px;
    background: #fff;
    text-align: center;
    color: #000;
    line-height: 20px;
    font-size: 13px;
}

.hotel_item_time ul:after {
    clear: both;
    content: '';
    display: table;
}

.hotel_item_time ul {
    margin: 0 -2.5px;
}

.hotel_item_time ul li {
    float: left;
    width: 25%;
    padding: 0 2.5px
}

.hotel_item_time .hotel_item_time_item {
    text-align: center;
    color: #333;
    border-radius: 2px;
    position: relative;
    border: 1px solid #ddd;
    min-height: 36px;
    padding: 12px 2px 2px
}

.hotel_item_time ul li span {
    display: block;
    margin-top: 2px;
}

.hotel_item_time ul li span:first-child {
    position: absolute;
    color: #fff;
    left: 15px;
    top: -10px;
    line-height: 20px;
    right: 15px;
    border-radius: 10px;
    background: #249fc0;
    margin: 0
}

.hotel_item_time ul li span.best {
    background: #35cccc;
    color: #ffffff;
}

.hotel_item_content {
    float: right;
    width: 430px;
    height: 230px;
    position: relative;
    padding-top: 25px;
}

.hotel_item_detail {
    float: left;
    width: 388px;
    height: 230px;
    position: relative;
    padding-right: 10px;
}

.hotel_item_detail ul li {
    position: relative;
    padding-left: 15px;
}

.hotel_item_detail ul li:after {
    clear: both;
    content: '';
    display: table;
}

.hotel_item_detail > ul > li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 6px;
    height: 6px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    transform: rotate(-45deg)
}

.hotel_item_detail > ul > li span,
.hotel_item_detail > ul > li a {
    line-height: 30px;
    display: block;
    color: #888;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;
}

.hotel_item_detail > ul > li a:hover {
    color: #626262;
}

.hotel_item_detail > ul > li span:first-child {
    color: #000;
    float: left;
    width: 100px;
}

.hotel_item_detail > ul > li > ul {
    float: left;
}

.hotel_item_rates:after {
    clear: both;
    content: '';
    display: table;
}

.hotel_item_rates {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 5;
}

.hotel_item_rate {
    position: relative;
    float: left;
    width: 83px;
    height: 15px;
    margin-top: 5px;
}

.hotel_item_rate_bg {
    position: absolute;
    left: 0;
    top: 0;
}

.hotel_item_rate_ab {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
}

.hotel_item_rate_ab_inner {
    width: 83px;
}

.hotel_item_rate_ab_inner:after {
    clear: both;
    content: '';
    display: table;
}

.hotel_item_rate .hotel_item_circle {
    float: left;
    width: 15px;
    height: 15px;
    background: #ddd;
    border-radius: 100%;
    margin-right: 2px;
    position: relative;
}

.hotel_item_rate .hotel_item_circle:after {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    border: 2px solid #fff;
    top: 1px;
    left: 1px;
    border-radius: 100%;
}

.hotel_item_rate .hotel_item_circle:last-child {
    margin-right: 0
}

.hotel_item_rate_ab .hotel_item_circle {
    background: #ff9900;
}

.hotel_item_review {
    float: left;
    margin-left: 5px;
    line-height: 25px;
    color: #333
}

.hotel_item_review span {
    display: block;
}

.hotel_item_favorite {
    float: right;
    width: 25px;
}

.hotel_item_favorite a {
    display: block;
    line-height: 25px;
    text-align: center;
    color: #888
}

.hotel_item_favorite a i {
    line-height: inherit;
    font-size: 18px;
    display: block;
}

#city_menu {
    padding: 30px 0
}

.city_art {
    background: #f7f7f7;
    padding: 10px;
}

.city_menu_list {
    background: #f7f7f7;
    padding: 10px;
}

.city_art img {
    max-height: 365px;
    display: block;
    margin: 0 auto
}

.city_menu_list ul li a {
    line-height: 60px;
    border-bottom: 1px solid #7de7ff;
    display: block;
    font-size: 17px;
    font-weight: 500;
    color: #2d3055;
}

.city_menu_list ul li f {
    line-height: 60px;
    border-bottom: 1px solid #7de7ff;
    display: block;
    font-size: 17px;
    font-weight: 500;
    color: #2d3055;
}

.city_menu_list ul li:last-child a {
    border-bottom: none
}

.city_menu_list ul li:last-child f {
    border-bottom: none
}

.city_menu_list ul li a:hover {
    color: #33cccc;
}

.city_menu_list ul li f:hover {
    cursor: pointer;
    color: #33cccc;
}

.city_menu_list ul li:hover .child {
    display: block;
    height: auto;

}

.city_menu_list ul li .child {
    -webkit-transition: height 2s; /* Safari */
    transition: height 2s;
    display: none;
    height: 0;
    background: #fff;
    margin: 20px;
    padding: 20px;

}

.city_menu_list ul li a i {
    float: left;
    width: 50px;
    text-align: center;
    line-height: inherit;
    font-size: 22px;
}

.city_menu_list ul li f i {
    float: left;
    width: 50px;
    text-align: center;
    line-height: inherit;
    font-size: 22px;
}

.city_menu_list ul li a span {
    background: #999;
    color: #fff;
    font-weight: 400;
    padding: 0 5px;
    line-height: 20px;
    margin-left: 10px;
    border-radius: 10px;
    font-size: 12px;
    display: inline-block;
}

.city_menu_list ul li f span {
    background: #999;
    color: #fff;
    font-weight: 400;
    padding: 0 5px;
    line-height: 20px;
    margin-left: 10px;
    border-radius: 10px;
    font-size: 12px;
    display: inline-block;
}

.city_menu_list ul li a:after {
    content: '\f2fb';
    float: right;
    font-family: material-design;
    line-height: inherit;
    color: #33cccc;
    font-size: 30px;
    width: 50px;
    text-align: center;
}

.city_menu_list ul li f:after {
    content: '\f2fb';
    float: right;
    font-family: material-design;
    line-height: inherit;
    color: #33cccc;
    font-size: 30px;
    width: 50px;
    text-align: center;
}

#city_parallax {
    background: #fff;
}

#city_parallax.fixed {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
}

#city_parallax ul:after {
    clear: both;
    content: '';
    display: table;
}

#city_parallax ul {
    display: flex;
}

#city_parallax ul li {
    flex: 1;
    text-align: center;
}

#city_parallax ul li a {
    display: block;
    line-height: 52px;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 3px solid transparent;
    color: #2d3055
}

#city_parallax ul li a.active {
    border-color: #33cccc;
}

#city_overview {
    padding: 30px 0;
    background: #f7f7f7
}

.city_overview {
    text-align: justify;
    line-height: 28px;
    font-size: 14px;
}

#city_map {
    height: 300px;
}

#city_overview_slider {
    padding: 40px 0
}

#city_overview_slider .swiper-slide {
    height: 420px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
}

#city_overview_slider .swiper-slide a {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 4;
    background: rgba(0, 0, 0, 0);
    transition: 0.3s
}

#city_overview_slider .swiper-slide a span {
    position: absolute;
    bottom: -50px;
    left: 15px;
    right: 15px;
    display: block;
    border-radius: 15px;
    background: #fff;
    line-height: 30px;
    padding: 0 10px;
    color: #222;
    font-size: 14px;
    text-align: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s
}

#city_overview_slider .swiper-slide:hover a {
    background: rgba(0, 0, 0, 0.4)
}

#city_overview_slider .swiper-slide:hover a span {
    bottom: 15px;
}

#city_overview_slider .button-prev,
#city_overview_slider .button-next {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s
}

#city_overview_slider .button-next {
    left: auto;
    right: 0
}

#city_overview_slider .swiper-container:hover .button-prev,
#city_overview_slider .swiper-container:hover .button-next {
    opacity: 1;
    visibility: visible;
}

#city_overview_slider .button-prev i,
#city_overview_slider .button-next i {
    position: absolute;
    line-height: 40px;
    font-size: 30px;
    margin-top: -10px;
    top: 50%;
    left: 0;
    right: 0;
}

#city_top_routes {
    padding: 40px 0
}

#city_top_sights {
    padding: 40px 0;
    background: #f7f7f7
}

.rates_star {
    position: relative;
    float: right;
    width: 83px;
    height: 15px;
    margin-top: 23px;
}

.rates_star_bg {
    position: absolute;
    left: 0;
    top: 0;
}

.rates_star_ab {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
}

.rates_star_ab_inner {
    width: 83px;
}

.rates_star_ab_inner:after {
    clear: both;
    content: '';
    display: table;
}

.rates_star_bg > div,
.rates_star_ab_inner > div {
    float: left;
    width: 15px;
    height: 15px;
    background: #ddd;
    border-radius: 100%;
    margin-right: 2px;
    position: relative;
}

.rates_star_bg > div:after,
.rates_star_ab_inner > div:after {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    border: 2px solid #fff;
    top: 1px;
    left: 1px;
    border-radius: 100%;
}

.rates_star_bg > div:last-child,
.rates_star_ab_inner > div:last-child {
    margin-right: 0
}

.rates_star_ab .rates_star_ab_inner > div {
    background: #ff9900;
}

.top_place {
    position: relative;
    padding-left: 50%;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
    min-height: 400px;
}

.top_place_image {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    min-height: 114px;
    background-size: cover;
    background-position: center;
    border-radius: 5px 0 0 5px;
    overflow: hidden;
}

.top_place_list {
    padding: 15px 15px 40px;
    position: relative;
}

.top_place_list ul {
    margin-bottom: 10px;
}

.top_place_list ul li a {
    line-height: 60px;
    border-bottom: 2px solid #7de7ff;
    display: block;
    font-size: 15px;
    color: #2d3055;
    padding-left: 10px;
}

.top_place_list ul li a:hover {
    color: #33cccc
}

.top_place_list ul li:last-child a {
    border-bottom: none;
}

.top_place_list ul li a span {
    background: #eee;
    color: #666;
    font-weight: 400;
    padding: 0 5px;
    line-height: 20px;
    margin-left: 10px;
    border-radius: 10px;
    font-size: 12px;
    display: inline-block;
}

.top_place_more {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.top_place_more a {
    display: block;
    background: #f5f5f5;
    line-height: 40px;
    padding: 0 15px;
    color: #2d3055;
    font-size: 16px;
    text-align: center;
    transition: 0.3s;
}

.top_place_more a:hover {
    background: #249fc0;
    color: #fff;
}

.top_place_more a i {
    float: right;
    line-height: inherit;
    margin-left: 5px;
    font-size: 22px
}

#city_places_to_stay {
    padding: 40px 0;
    background: #f7f7f7
}

.place_stay_item {
    background: #fff;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
    margin-bottom: 15px;
}

.place_stay_item .place_stay_image {
    height: 270px;
    background-position: center;
    background-size: cover;
    position: relative;
}

.place_stay_item .place_stay_image a {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 3;
}

.place_stay_item .place_stay_image:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    transition: 0.3s;
}

.place_stay_item:hover .place_stay_image:before {
    opacity: 1;
}

.place_stay_content {
    padding: 10px 10px 50px;
}

.place_stay_content:after {
    clear: both;
    content: '';
    display: table;
}

.place_stay_content .place_stay_content_col_left {
    float: left;
    width: 335px;
}

.place_stay_rate:after {
    clear: both;
    content: '';
    display: table;
}

.place_stay_rate .rates_star {
    float: left;
    margin: 12.5px 10px 12.5px 0;
}

.place_stay_rate > span {
    float: left;
    line-height: 40px;
    color: #999
}

.place_stay_name:after {
    clear: both;
    content: '';
    display: table;
}

.place_stay_name h2 {
    line-height: 40px;
    font-size: 18px;
    float: left;
    margin-right: 10px;
}

.place_stay_name h2 a {
    color: #2d3055
}

.place_stay_name h2 span {
    font-size: 14px;
    margin-right: 5px;
    background: #eee;
    line-height: 20px;
    padding: 0 10px;
    border-radius: 10px;
    color: #666;
    float: left;
    margin-top: 10px;
}

.place_stay_star {
    float: left;
    width: 80px;
    margin: 10px 0
}

.place_stay_star i {
    float: left;
    font-size: 18px;
    color: #ffd26e;
    width: 16px;
    text-align: center;
    line-height: 20px;
}

.place_stay_content .place_stay_content_col_right {
    float: right;
    width: 205px;
    border-left: 1px solid #eee;
    padding-left: 5px;
}

.place_stay_content_col_right ul li {
    line-height: 26px;
    font-size: 13px;
}

.place_stay_content_col_right ul li i {
    float: left;
    text-align: center;
    line-height: inherit;
    font-size: 18px;
    color: #249fc0;
    margin-right: 5px;
}

.place_stay_btns {
    float: right;
    margin-top: 5px;
}

.place_stay_btns > ul > li {
    float: right;
    width: 30px;
    line-height: 30px;
    text-align: center;
    position: relative;
    margin-left: 5px;
}

.place_stay_btns > ul > li:last-child {
    margin-left: 0
}

.place_stay_btns > ul > li > a {
    display: block;
    line-height: inherit;
    font-size: 22px;
    color: #999
}

.place_stay_btns > ul > li > ul {
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    border-radius: 15px;
    white-space: nowrap;
    height: 30px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s
}

.place_stay_btns > ul > li:hover > ul {
    visibility: visible;
    opacity: 1
}

.place_stay_btns > ul > li > ul > li {
    width: 30px;
    display: inline-block;
    height: 30px;
}

.place_stay_btns > ul > li > ul > li a {
    line-height: 30px;
    text-align: center;
    display: block;
    font-size: 16px;
    color: #999
}

.place_stay_btns > ul > li > ul > li a:hover {
    color: #2d3055
}

.play_stay_price {
    line-height: 40px;
    font-size: 15px;
}

.play_stay_price span {
    display: inline-block;
}

.play_stay_price span small {
    margin-left: 5px;
    background: #ddd;
    color: #666;
    font-size: 12px;
    padding: 0 10px;
    line-height: 15px;
    border-radius: 7.5px;
}

.place_stay_overview {
    float: left;
    width: 100%;
}

.place_stay_overview p {
    line-height: 20px;
    font-size: 13px;
    text-align: justify;
    height: 60px;
    overflow: hidden;
    position: relative;
    margin-bottom: 0
}

.place_stay_overview.show_more p {
    height: auto;
}

.place_stay_overview p:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(to top, #fff, transparent);
    z-index: 2;
    content: '';
}

.place_stay_overview.show_more p:after {
    display: none
}

.place_stay_overview span {
    line-height: 25px;
    font-weight: 600;
    font-size: 14px;
}

.place_stay_overview a.more_overview {
    display: block;
    line-height: 15px;
    font-weight: 500;
    text-align: center;
}

.place_stay_overview a.more_overview:after {
    content: '\f2f9';
    font-family: material-design;
    position: relative;
    line-height: 5px;
    margin-left: 5px;
    font-size: 16px;
    top: 2px;
}

.place_stay_info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: #f7f7f7
}

.place_stay_info ul:after {
    clear: both;
    content: '';
    display: table;
}

.place_stay_info ul li {
    float: left;
    width: 33.333333%;
    padding: 0 10px;
    line-height: 40px;
    text-align: center;
    font-size: 13px;
    font-weight: 500
}

.place_stay_info ul li span,
.place_stay_info ul li a {
    line-height: inherit;
    color: #666
}

.place_stay_info ul li i {
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    position: relative;
    top: 2px;
    margin-right: 5px;
    color: #249fc0
}

.view_more {
    text-align: center;
    margin-top: 15px;
}

.view_more a {
    display: inline-block;
    background: #33cccc;
    line-height: 40px;
    border-radius: 20px;
    padding: 0 35px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    transition: 0.3s;
}

.view_more a:hover {
    background: #249fc0;
}

.view_more a i {
    float: right;
    line-height: inherit;
    margin-left: 5px;
    font-size: 22px;
}

.place_eat_item {
    background: #fff;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
    margin-bottom: 15px;
}

.place_eat_item .place_eat_image {
    height: 270px;
    background-position: center;
    background-size: cover;
    position: relative;
}

.place_eat_item .place_eat_image a {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 3;
}

.place_eat_item .place_eat_image:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    transition: 0.3s;
}

.place_eat_item:hover .place_eat_image:before {
    opacity: 1;
}

.place_eat_content {
    padding: 10px 10px 50px;
}

.place_eat_content:after {
    clear: both;
    content: '';
    display: table;
}

.place_eat_content .place_eat_content_col_left {
    float: left;
    width: 335px;
}

.place_eat_desc {
    line-height: 25px;
    font-size: 13px;
}

.place_eat_name:after {
    clear: both;
    content: '';
    display: table;
}

.place_eat_time {
    float: right;
    margin: 10px 0
}

.place_eat_time:after {
    clear: both;
    content: '';
    display: table;
}

.place_eat_time span {
    float: left;
    line-height: 20px;
    background: #eee;
    padding: 0 10px;
    margin-right: 3px;
    border-radius: 10px;
}

.place_eat_content h2 {
    line-height: 40px;
    font-size: 18px;
    float: left;
    margin-right: 10px;
}

.place_eat_content h2 a {
    color: #2d3055
}

.place_eat_content h2 span {
    font-size: 14px;
    margin-right: 5px;
    background: #eee;
    line-height: 20px;
    padding: 0 10px;
    border-radius: 10px;
    color: #666;
    float: left;
    margin-top: 10px;
}

.place_eat_content .place_eat_content_col_right {
    float: right;
    width: 205px;
    border-left: 1px solid #eee;
    padding-left: 5px;
}

.place_eat_content_col_right > span {
    line-height: 25px;
    font-weight: 600;
    font-size: 14px;
}

.place_eat_content_col_right ul li {
    line-height: 26px;
    font-size: 12px;
}

.place_eat_content_col_right ul li span:last-child {
    float: right;
    font-weight: 600
}

.place_stay_overview {
    float: left;
    width: 100%;
}

.place_stay_overview p {
    line-height: 20px;
    font-size: 13px;
    text-align: justify;
    height: 60px;
    overflow: hidden;
    position: relative;
    margin-bottom: 0
}

.place_stay_overview.show_more p {
    height: auto;
}

.place_stay_overview p:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(to top, #fff, transparent);
    z-index: 2;
    content: '';
}

.place_stay_overview.show_more p:after {
    display: none
}

.place_stay_overview span {
    line-height: 25px;
    font-weight: 600;
    font-size: 14px;
}

.place_stay_overview a.more_overview {
    display: block;
    line-height: 15px;
    font-weight: 500;
    text-align: center;
}

.place_stay_overview a.more_overview:after {
    content: '\f2f9';
    font-family: material-design;
    position: relative;
    line-height: 5px;
    margin-left: 5px;
    font-size: 16px;
    top: 2px;
}

.place_eat_info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: #f7f7f7
}

.place_eat_info ul:after {
    clear: both;
    content: '';
    display: table;
}

.place_eat_info ul li {
    float: left;
    width: 33.333333%;
    padding: 0 10px;
    line-height: 40px;
    text-align: center;
    font-size: 13px;
    font-weight: 500
}

.place_eat_info ul li span,
.place_eat_info ul li a {
    line-height: inherit;
    color: #666
}

.place_eat_info ul li i {
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    position: relative;
    top: 2px;
    margin-right: 5px;
    color: #249fc0
}

#city_places_to_eat {
    padding: 40px 0
}

#hotel_map_box {
    padding: 40px 0
}

#hotels_map {
    height: 450px;
}

.filter_rating {
    width: 140px;
}

.filter_row .more_content {
    display: none
}

.filter_row .more_content.opened {
    display: block;
}

a.more_toggle {
    line-height: 18px;
    display: inline-block;
    padding: 0 10px;
    background: #33cccc;
    color: #fff;
    border-radius: 9px;
}

a.more_toggle:after {
    content: '\f2f9';
    font-family: material-design;
    float: right;
    line-height: inherit;
    margin-left: 4px;
}

.more_toggle.open:after {
    content: '\f2fc';
}

.filter_rating:after {
    clear: both;
    content: '';
    display: table;
}

.filter_rating > label {
    float: right;
    width: 28px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 25px;
    line-height: 30px;
    color: #ddd;
    text-align: center;
    margin: 0
}

.filter_rating > label i {
    display: block;
    line-height: inherit;
}

.filter_rating > input:checked + label:hover,
.filter_rating > input:checked + label:hover ~ label,
.filter_rating > input:checked ~ label:hover,
.filter_rating > input:checked ~ label:hover ~ label,
.filter_rating > label:hover ~ input:checked ~ label,
.filter_rating:not(:checked) > label:hover,
.filter_rating:not(:checked) > label:hover ~ label {
    color: #fba500;
}

.filter_rating > input:checked ~ label {
    color: #f70;
}

.filter_rating > input {
    display: none
}

#hotel_detail_header {
    padding: 10px 0;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15)
}

.place_stay_info_header ul:after {
    clear: both;
    content: '';
    display: table;
}

.place_stay_info_header ul li {
    line-height: 30px;
    font-size: 13px;
    font-weight: 500;
    float: left;
    width: 50%;
}

.place_stay_info_header ul li:first-child {
    width: 100%;
}

.place_stay_info_header ul li i {
    font-size: 18px;
    line-height: inherit;
    float: left;
    margin-right: 10px;
    color: #249fc0;
}

.place_stay_info_header ul li span,
.place_stay_info_header ul li a {
    line-height: inherit;
    color: #666;
}

#hotel_detail_slider {
    padding: 40px 0
}

#hotel_detail_slider .swiper-slide {
    height: 420px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
}

#hotel_detail_slider .swiper-slide a {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 4;
    background: rgba(0, 0, 0, 0);
    transition: 0.3s
}

#hotel_detail_slider .swiper-slide a span {
    position: absolute;
    bottom: -50px;
    left: 15px;
    right: 15px;
    display: block;
    border-radius: 15px;
    background: #fff;
    line-height: 30px;
    padding: 0 10px;
    color: #222;
    font-size: 14px;
    text-align: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s
}

#hotel_detail_slider .swiper-slide:hover a {
    background: rgba(0, 0, 0, 0.4)
}

#hotel_detail_slider .swiper-slide:hover a span {
    bottom: 15px;
}

#hotel_detail_slider .button-prev,
#hotel_detail_slider .button-next {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s
}

#hotel_detail_slider .button-next {
    left: auto;
    right: 0
}

#hotel_detail_slider .swiper-container:hover .button-prev,
#hotel_detail_slider .swiper-container:hover .button-next {
    opacity: 1;
    visibility: visible;
}

#hotel_detail_slider .button-prev i,
#hotel_detail_slider .button-next i {
    position: absolute;
    line-height: 40px;
    font-size: 30px;
    margin-top: -10px;
    top: 50%;
    left: 0;
    right: 0;
}

.hotel_overview {
    float: left;
    width: 100%;
}

.hotel_overview p {
    line-height: 25px;
    font-size: 14px;
    text-align: justify;
    height: 150px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
}

.hotel_overview.show_more p {
    height: auto;

}

.hotel_overview p:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background: linear-gradient(to top, #f7f7f7, transparent);
    z-index: 2;
    content: '';
}

.hotel_overview.show_more p:after {
    display: none
}

a.more_btn {
    display: inline-block;
    line-height: 15px;
    font-weight: 500;
    text-align: center;
    color: #33cccc
}

a.more_btn:after {
    content: '\f2f9';
    font-family: material-design;
    position: relative;
    line-height: 5px;
    margin-left: 5px;
    font-size: 16px;
    top: 2px;
}

#hotel_map {
    height: 200px
}

.hotel_amenities ul {
    margin-bottom: 10px;
}

.hotel_amenities ul li {
    line-height: 30px;
    font-size: 14px;
}

.hotel_amenities ul li i {
    float: left;
    text-align: center;
    line-height: inherit;
    font-size: 22px;
    color: #249fc0;
    margin-right: 5px;
}

.hotel_amenities ul li.item_more {
    display: none
}

.hotel_amenities.show_more ul li.item_more {
    display: block;
}

#hotel_detail_overview {
    padding: 40px 0;
    background: #f7f7f7
}

#hotel_detail_review {
    padding: 40px 0
}

.place_stay_rate_big:After {
    clear: both;
    content: '';
    display: table;
}

.place_stay_rate_big {
    margin-bottom: 25px;
}

.place_stay_rate_big > span {
    float: left;
    line-height: 40px;
    color: #666;
    font-size: 15px;
}

.place_stay_rate_big > span:first-child {
    font-size: 30px;
}

.rates_star_big {
    position: relative;
    float: left;
    width: 158px;
    height: 30px;
    margin: 5px 10px;
}

.rates_star_big .rates_star_bg {
    position: absolute;
    left: 0;
    top: 0;
}

.rates_star_big .rates_star_ab {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
}

.rates_star_big .rates_star_ab_inner {
    width: 158px;
}

.rates_star_big .rates_star_ab_inner:after {
    clear: both;
    content: '';
    display: table;
}

.rates_star_big .rates_star_bg > div,
.rates_star_big .rates_star_ab_inner > div {
    float: left;
    width: 30px;
    height: 30px;
    background: #ddd;
    border-radius: 100%;
    margin-right: 2px;
    position: relative;
}

.rates_star_big .rates_star_bg > div:after,
.rates_star_big .rates_star_ab_inner > div:after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border: 3px solid #fff;
    top: 3px;
    left: 3px;
    border-radius: 100%;
}

.rates_star_big .rates_star_bg > div:last-child,
.rates_star_big .rates_star_ab_inner > div:last-child {
    margin-right: 0
}

.rates_star_big .rates_star_ab .rates_star_ab_inner > div {
    background: #ff9900;
}

.rate_bars ul li:after {
    clear: both;
    content: '';
    display: table;
}

.rate_bars ul li {
    position: relative;
    line-height: 20px;
    margin-bottom: 15px;
    padding: 0 35px 0 70px;
    font-size: 14px;
}

.rate_bars ul li > span:first-child {
    position: absolute;
    left: 0;
    top: 0;
    line-height: inherit;
}

.rate_bars ul li > span:last-child {
    position: absolute;
    right: 0;
    top: 0;
    line-height: inherit;
}

.rate_bar {
    height: 20px;
    border-radius: 10px;
    border: 1px solid #666;
    position: relative;
    overflow: hidden;
}

.rate_bar div {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: #33cccc
}

.review_modal_btn {
    text-align: center;
    padding-top: 75px;
}

.review_modal_btn a {
    display: inline-block;
    background: #33cccc;
    line-height: 40px;
    border-radius: 20px;
    padding: 0 35px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    transition: 0.3s;
}

.review_modal_btn a:hover {
    background: #249fc0;
}

#comments {
    padding: 40px 0
}

.comment_item {
    padding-left: 120px;
    position: relative;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.comment_item:last-child {
    margin-bottom: 0;
    border-bottom: 0
}

.comment_rate .rates_star {
    float: none;
    margin-bottom: 10px;
}

.comment_avatar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
}

.comment_avatar img {
    width: 100px;
    height: 100px;
    border-radius: 100%
}

.comment_avatar span {
    display: block;
    line-height: 30px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
}

.comment_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 10px;
}

.comment_text {
    font-size: 13px;
    line-height: 20px;
    text-align: justify;
}

.comment_btns:after {
    clear: both;
    content: '';
    display: table;
}

.comment_btns {
    margin-bottom: 10px;
}

.comment_like {
    float: left;
    margin-right: 15px;
}

.comment_like span {
    font-size: 14px;
    color: #999
}

.comment_like a {
    font-size: 20px;
    color: #999;
}

.comment_like a:hover {
    color: #2d3055
}

.comment_report {
    float: left;
}

.comment_report > ul > li {
    position: relative;
}

.comment_report > ul > li > a {
    font-size: 20px;
    color: #999;
    display: block;
}

.comment_report > ul > li:hover > a {
    color: #2d3055
}

.comment_report > ul > li > ul {
    display: none;
    width: 180px;
    background: #fff;
    z-index: 9;
    position: absolute;
    left: 0;
    top: 100%;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2)
}

.comment_report > ul > li:hover > ul {
    display: block;
}

.comment_report > ul > li > ul > li a {
    line-height: 25px;
    display: block;
    color: #666;
    padding: 0 10px;
}

.comment_report > ul > li > ul > li:hover a {
    background: #eee
}

.comment_images ul:after {
    clear: both;
    content: '';
    display: table;
}

.comment_images ul li {
    float: left;
    margin-right: 15px;
    border: 1px solid #eee;
    padding: 3px;
}

.comment_images ul li a {
    display: block;
    position: relative;
}

.comment_images ul li a:after {
    position: absolute;
    content: '\f1c3';
    font-family: material-design;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    text-align: center;
    font-size: 25px;
    line-height: 70px;
    color: #fff;
}

.comment_images ul li:hover a:after {
    opacity: 1;
    visibility: visible;
}

.comment_images ul li a img {
    width: 100px;
    height: 70px;
}

.review_rating:after {
    clear: both;
    content: '';
    display: table;
}

.review_rating {
    float: left;
}

.review_rating_title {
    float: left;
    line-height: 30px;
    padding: 0 5px;
    background: #eee;
    margin-left: 15px;
    position: relative;
}

.review_rating_title:before {
    content: '';
    position: absolute;
    left: -10px;
    top: 0;
    border-right: 10px solid #eee;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}

.review_rating > label {
    float: right;
    width: 28px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 30px;
    line-height: 30px;
    color: #ddd;
    text-align: center;
    margin: 0
}

.review_rating > label i {
    display: block;
    line-height: inherit;
}

.review_rating > input:checked + label:hover,
.review_rating > input:checked + label:hover ~ label,
.review_rating > input:checked ~ label:hover,
.review_rating > input:checked ~ label:hover ~ label,
.review_rating > label:hover ~ input:checked ~ label,
.review_rating:not(:checked) > label:hover,
.review_rating:not(:checked) > label:hover ~ label {
    color: #fba500;
}

.review_rating > input:checked ~ label {
    color: #f70;
}

.review_rating > input {
    display: none
}

.form_review .form-group:after {
    clear: both;
    content: '';
    display: table;
}

.letsgo_btn {
    background: #33cccc;
    color: #fff;
    font-size: 1rem;
    border-radius: 3px;
    padding: 10px 40px;
}

.next_verify {
    background-size: contain;
    background-position: center;
}

.letsgo_btn :hover {
    color: #fff;
}

.content-alaram-body {
    background: rgba(0, 0, 0, 0.5);
    min-height: 250px;
    border: 4px solid #97d8ef;
}

.content-alaram-body-c {

    border: 4px solid #FF8585;
}

.tick_alarm_active {
    width: 100px;
    height: 100px;
    border: 4px solid #97d8ef;
    border-radius: 50%;
    position: absolute;
    left: 40%;
    top: -20%;
}

.invalid_alarm_active {
    width: 100px;
    height: 100px;
    border: 4px solid #FF8585;
    border-radius: 50%;
    position: absolute;
    left: 41.5%;
    top: -20%;
}

.alarm-content h4 {
    font-family: RobotoT;
}

.alarm-content h4 b {
    font-family: RobotoL;
}

.alarm-content h4 span {
    font-size: 1.4rem;
}

.alarm-content h6 {
    font-family: RobotoL;
    font-size: 1.45rem;
}

.alarm-content span {
    font-family: RobotoL;
    font-size: 1rem;
    margin: 10px;
}

.form_review .form-group, .local_guide_review_form {
    margin-bottom: 15px;
}

.form_review .form-group > label {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    display: block;
}

.form_review .inline_label {
    float: left;
    margin-right: 10px;
}

.form_review .select2picker {
    min-width: 100%;
}

.form_review .select2-container {
    width: 100%;
    display: block;
}

.form_review textarea {
    min-height: 100px;
}

.btn_center {
    text-align: center;
}

.form_review button.btn {
    line-height: 40px;
    border-radius: 20px;
    padding: 0 35px;
}

.form_review button.btn:hover {
    background: #249fc0;
}

.review_images:after {
    clear: both;
    content: '';
    display: table;
}

.review_image {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
    font-size: 35px;
    text-align: center;
    line-height: 80px;
    background: #eee;
    margin-right: 10px;
    margin-bottom: 10px;
    float: left;
    transition: 0.3s
}

.review_image:before {
    content: '\f17e';
    font-family: Material-Design;
    display: block;
    line-height: inherit;
}

.review_image i {
    position: absolute;
    line-height: 30px;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    display: none;
    z-index: 10;
    cursor: pointer;
    font-size: 25px;
}

.review_image.set_val:hover i {
    display: block;
}

.review_image input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
    cursor: pointer;
    z-index: 5;
}

.review_image img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
}

.review_image.set_val img {
    display: block;
}

.add_review_image {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
    font-size: 35px;
    text-align: center;
    line-height: 80px;
    background: #eee;
    float: left;
    cursor: pointer;
    transition: 0.3s
}

.review_image:hover,
.add_review_image:hover {
    background: #ddd
}

#food_drink_map_box {
    padding: 40px 0
}

#food_drink_map {
    height: 450px;
}

#food_drink_places_to_eat {
    padding: 40px 0;
    background: #f7f7f7
}

.place_eat_overview {
    float: left;
    width: 100%;
}

.place_eat_overview p {
    line-height: 20px;
    font-size: 13px;
    text-align: justify;
    height: 60px;
    overflow: hidden;
    position: relative;
    margin-bottom: 0
}

.place_eat_overview.show_more p {
    height: auto;
}

.place_eat_overview p:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(to top, #fff, transparent);
    z-index: 2;
    content: '';
}

.place_eat_overview.show_more p:after {
    display: none
}

.place_eat_overview span {
    line-height: 25px;
    font-weight: 600;
    font-size: 14px;
}

.place_eat_overview a.more_overview {
    display: block;
    line-height: 15px;
    font-weight: 500;
    text-align: center;
}

.place_eat_overview a.more_overview:after {
    content: '\f2f9';
    font-family: material-design;
    position: relative;
    line-height: 5px;
    margin-left: 5px;
    font-size: 16px;
    top: 2px;
}

.rout_detail_image img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: 400px;
    border-radius: 5px;
}

#route_detail_slider {
    padding: 40px 0;
}

#route_detail_slider .swiper-slide {
    background-position: center;
    background-size: cover;
    height: 220px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

#route_detail_slider .swiper-slide a {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    z-index: 5;
    opacity: 0;
    transition: 0.3s;
    visibility: hidden;
}

#route_detail_slider .swiper-slide:hover a {
    visibility: visible;
    opacity: 0.9
}

#route_detail_slider .button-prev,
#route_detail_slider .button-next {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s
}

#route_detail_slider .button-next {
    left: auto;
    right: 0
}

#route_detail_slider .swiper-container:hover .button-prev,
#route_detail_slider .swiper-container:hover .button-next {
    opacity: 1;
    visibility: visible;
}

#route_detail_slider .button-prev i,
#route_detail_slider .button-next i {
    position: absolute;
    line-height: 40px;
    font-size: 30px;
    margin-top: -10px;
    top: 50%;
    left: 0;
    right: 0;
}

#route_detail {
    padding: 40px 0
}

#route_map_box {
    padding: 40px 0;
}

#route_map {
    height: 450px;
}

#route_parallax {
    background: #fff;
}

#route_parallax ul {
    display: flex;
}

#route_parallax.fixed {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
}

#route_parallax ul:after {
    clear: both;
    content: '';
    display: table;
}

#route_parallax ul li {
    flex: 1;
    text-align: center;
}

#route_parallax ul li a {
    display: block;
    line-height: 52px;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 3px solid transparent;
    color: #2d3055
}

#route_parallax ul li a.active {
    border-color: #33cccc;
}

#route_important_info {
    padding: 40px 0;
    background: #f7f7f7
}

#route_what_to_expect {
    padding: 40px 0;
    line-height: 30px;

}

#route_exploring {
    padding: 40px 0;
}

#route_nearby {
    padding: 40px 0;
    background: #f7f7f7
}

#route_itinerary {
    padding: 40px 0;
    background: #f7f7f7
}

#route_local_guides {
    padding: 40px 0;
}

#route_reviews {
    padding: 40px 0;
}

.rout_detail_content {
    position: relative;
    min-height: 400px;
}

.route_stay_btns {
    position: absolute;
    right: 0;
    top: 0;
}

.route_stay_btns > ul > li {
    float: right;
    width: 30px;
    line-height: 30px;
    text-align: center;
    position: relative;
    margin-left: 5px;
}

.route_stay_btns > ul > li:last-child {
    margin-left: 0
}

.route_stay_btns > ul > li > a {
    display: block;
    line-height: inherit;
    font-size: 22px;
    color: #999
}

.route_stay_btns > ul > li > ul {
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    border-radius: 15px;
    white-space: nowrap;
    height: 30px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s
}

.route_stay_btns > ul > li:hover > ul {
    visibility: visible;
    opacity: 1
}

.route_stay_btns > ul > li > ul > li {
    width: 30px;
    display: inline-block;
    height: 30px;
}

.route_stay_btns > ul > li > ul > li a {
    line-height: 30px;
    text-align: center;
    display: block;
    font-size: 16px;
    color: #999
}

.route_stay_btns > ul > li > ul > li a:hover {
    color: #2d3055
}

.route_item_locations {
    padding-top: 15px;
    border-top: 1px solid #eee;
    position: relative;
    margin-top: 10px;
}

.route_item_locations > span {
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -42.5px;
    width: 85px;
    padding: 0 5px;
    background: #fff;
    text-align: center;
    color: #000;
    line-height: 20px;
    font-size: 13px;
}

.route_item_what_to_expect {
    padding-top: 15px;
    border-top: 1px solid #eee;
    position: relative;
    margin-top: 30px;
}

#what_to_expect {
    line-height: 30px;

}

.route_item_what_to_expect > span {
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -70px;
    background: #fff;
    text-align: center;
    color: #33CCCC;
    line-height: 20px;
    font-size: 1.1rem;
}

.route_item_locations ul:after {
    clear: both;
    content: '';
    display: table;
}

.route_item_locations ul li {
    float: left;
    width: 33.333333%;
    line-height: 30px;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.route_item_locations ul li:before {
    content: '';
    float: left;
    width: 20px;
    height: 20px;
    background: url("../images/svg/location_symbol.svg") no-repeat;
    margin-top: 4px;
    margin-right: 5px;
    position: relative;
    background-size: contain;
    background-position: center;

}

.freeTour {

    width: 100%;
    position: absolute;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    height: 45px;
    padding: 2.5px;

}

.freeTour div {

    width: 100%;
    height: 40px;

    color: #fff;
    background: url("../images/svg/free_trip.svg") no-repeat;
    background-size: contain;
    background-position: left;
    margin-left: 33%;
    text-align: left;
    padding-left: 40px;
    padding-top: 12px;
    font-family: RobotoB;
}

.freeTour div span {

}

.tour_highlights {
    padding-top: 15px;
    position: relative;
    margin-top: 30px;
    border-top: 1px solid #eee;
}

.tour_highlights > span {
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -35px;
    background: #fff;
    text-align: center;
    color: #33cccc;
    line-height: 20px;
    font-size: 1rem;
}

.tour_highlights ul:after {
    clear: both;
    content: '';
    display: table;
}

.tour_highlights ul {
    margin: 10px;

}

.tour_highlights ul li {
    float: left;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tour_highlights ul li span {
    line-height: 30px;
    font-family: RobotoL;
    overflow: hidden;
    font-size: .9rem;
    text-overflow: ellipsis;
}

.group-size-hd {
    margin: 0 0 20px 0;
}

.tour_highlights ul li:before {
    content: '';
    float: left;
    width: 10px;
    height: 10px;
    background: #7de7ff;
    border-radius: 100%;
    margin-top: 9px;
    margin-right: 5px;
    position: relative;
    border: 1px solid #249fc0;
    box-shadow: 0 0 0 2px #fff inset;
}

.route_item_detail ul li {
    position: relative;
    padding-left: 15px;
}

.route_item_detail ul li:after {
    clear: both;
    content: '';
    display: table;
}

.route_item_detail > ul > li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 6px;
    height: 6px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    transform: rotate(-45deg)
}

.route_item_detail > ul > li span {
    line-height: 30px;
    display: inline-block;
    color: #888;
    font-size: 14px;
    margin-left: 10px;
}

.route_item_detail > ul > li h1 {
    line-height: 30px;
    display: inline-block;
    color: #888;
    font-size: 14px;
}

.route_item_detail > ul > li span:first-child {
    color: #000;
    float: left;
    width: 120px;
    margin-left: 0px;

}

.important_info_table {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
}

.important_info_table_header {
    background: #555;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
    margin-bottom: 10px;
}

.important_info_table_row:after,
.important_info_table_header:after {
    clear: both;
    content: '';
    display: table;
}

.important_info_table_row {
    margin-bottom: 15px;
}

.important_info_table_row:last-child {
    margin-bottom: 0
}

.important_info_table_row ul li,
.important_info_table_header ul li {
    line-height: 40px;
    float: left;
    width: 20%;
    line-height: 40px;
    font-weight: 500;
    font-size: 13px
}

.view_map {
    display: inline-block;
    line-height: inherit;
    font-size: 24px;
    width: 40px;
}

.important_info_table_row ul li.dbl {
    width: 20%;
}

.page404 {
    background: url("../images/svg/404.svg") no-repeat;
    width: 100%;
    height: 300px;
    background-position: center;
    background-size: contain;
}

.page404car {
    background: url("../images/svg/car.svg") no-repeat;
    width: 100%;
    height: 100px;
    background-position: center;
    background-size: contain;
    margin-top: 5rem;
    position: relative;
    -webkit-animation-name: moveCar;
    -webkit-animation-duration: 4s;
    animation-name: moveCar;
    animation-duration: 4s;
}

@-webkit-keyframes moveCar {
    0% {
        left: -1500px;
    }

    100% {
        left: 0px;
    }
}

@keyframes moveCar {
    0% {
        left: -1500px;
    }

    100% {
        left: 0px;;
    }
}

.notFound {
    text-align: center;
    display: block;
}

.notFound h1 {
    margin: 20px 20px 4rem;
    color: rgb(125, 231, 255);
}

.notFound h4 {
    margin: 20px;
    font-size: 2.5rem;
}

.notFound h6 {
    font-family: RobotoL;
    font-size: 1.5rem;
    margin: 10px;

}

.page404text {
    opacity: 0;

    -webkit-animation-name: fadeText;
    -webkit-animation-duration: 2s;
    animation-name: fadeText;
    animation-duration: 2s;
    animation-delay: 4s;
    animation-fill-mode: forwards;

}

.page404alarm {
    opacity: 0;
    background: url("../images/svg/alarm.svg") no-repeat;
    width: 100%;
    height: 40px;
    margin-top: 38px;
    background-position: center;
    background-size: contain;
    position: absolute;
    -webkit-animation-name: fadeText;
    -webkit-animation-duration: 2s;
    animation-name: fadeText;
    animation-duration: 2s;
    animation-delay: 4s;
    animation-fill-mode: forwards;

}

@-webkit-keyframes fadeText {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeText {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.notFound h1 span {
    color: rgb(223, 34, 49);
}

.important_info_table_row ul li.alphabet span {
    margin-left: 50px;
    font-size: 1rem;
    vertical-align: top;
}

.important_info_table_row ul li.dbl span {
    float: left;
    width: 100%;
    position: relative;
}

.important_info_table_row ul li.dbl span:first-child {
    border-radius: 5px;
    background: #999;
    color: #fff
}

.important_info_table_row ul li.dbl span:last-child {
    border-radius: 5px;
    border: 1px solid #999;
    line-height: 38px;
}

.important_info_table_row ul li.dbl small {
    position: absolute;
    line-height: 14px;
    width: 50px;
    bottom: 0;
    margin-bottom: -7px;
    left: 50%;
    background: #df2231;
    color: #fff;
    text-align: center;
    border-radius: 7px;
    margin-left: -25px;
    font-size: 11px
}

.sec_inner_title {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee
}

.sec_inner_title h4 {
    font-size: 15px;
    line-height: 30px;
    display: inline-block;
    padding-right: 10px;
    border-bottom: 1px solid #33cccc;
    margin-bottom: -1px;
}

.sec_inner_desc {
    line-height: 25px;
    text-align: justify;
    font-size: 13px;
}

.sec_inner_list_title {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500
}

.sec_inner_list ul {
    margin-bottom: 25px;
}

.sec_inner_list ul li:after {
    clear: both;
    content: '';
    display: table;
}

.sec_inner_list ul li {
    border-bottom: 1px solid #eee;
}

.sec_inner_list ul li:before {
    width: 5px;
    height: 5px;
    background: #d7941d;
    content: '';
    float: left;
    margin-top: 9.5px;
    margin-right: 7px;
    transform: rotate(-45deg);
    transition: 0.3s;
}

.sec_inner_list ul li:last-child {
    border-bottom: none
}

.sec_inner_list ul li span {
    font-size: 13px;
    line-height: 25px;
    float: left;
    color: #666
}

.sec_inner_list ul li span:last-child {
    float: right;
    color: #111
}

.additional_list ul li {
    font-size: 13px;
    line-height: 25px;
    float: left;
    color: #666;
    border-bottom: 1px solid #eee
}

.additional_list ul li:last-child {
    border-bottom: none
}

.additional_list ul li:before {
    width: 5px;
    height: 5px;
    background: #ccc;
    content: '';
    float: left;
    margin-top: 9.5px;
    margin-right: 7px;
    transform: rotate(-45deg);
    transition: 0.3s;
}

.additional_list ul li a {
    float: right;
    color: #33cccc
}

.additional_list ul li a i {
    line-height: inherit;
    float: right;
    margin-left: 5px;
    font-size: 16px;
}

.cost_list ul li:after {
    clear: both;
    content: '';
    display: table;
}

.cost_list ul li {
    line-height: 40px;
    font-size: 13px;
}

.cost_list ul li span:last-child {
    float: right;
}

.cost_list .select2picker {
    float: right;
}

.cost_list .select2-container {
    float: right;
    margin-left: 5px;
    max-width: 150px
}

.sec_text {
    text-align: justify;
    font-size: 14px;
    margin-bottom: 30px
}

.sec_text #overview {
    position: relative;
    line-height: 30px;
    height: 350px;
    overflow: hidden;
}

.sec_text.show_more #overview {
    overflow: visible;
    height: auto
}

.sec_text #overview:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 2;
    content: '';
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgb(255, 255, 255) 100%);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgb(255, 255, 255) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgb(255, 255, 255) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgb(255, 255, 255) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(255, 255, 255) 100%);
}

.sec_text.show_more #overview:after {
    display: none;
}

.sec_text #read_more_a {
    display: inline-block;
    line-height: 15px;
    font-weight: 500;
    text-align: center;
    float: right;
    color: #33cccc
}

.h-90 {
    height: 90px
}

.sec_text #read_more_a:after {
    content: '\f2f9';
    font-family: material-design;
    position: relative;
    line-height: 5px;
    margin-left: 5px;
    font-size: 16px;
    top: 2px;
}

.sec_inner_title h4 {
    font-size: 1.2rem;
}

.highlight_list .tabs > ul {
    margin: 0 -10px 10px;
}

.highlight_list .tabs > ul li {
    float: left;
    width: 20%;
    padding: 0 10px;
}

.highlight_list .tabs > ul li span {
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    display: block;
    padding: 0 10px;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.highlight_list .tabs > ul li:hover span {
    color: #249fc0;
}

.highlight_list .tabs > ul li.active span {
    background: #249fc0;
    color: #fff
}

.tabs .tab_content h3 {
    font-size: 17px;
    margin: 15px 0 10px 0;
}

.highlight_list .tabs > ul li i {
    float: left;
    line-height: inherit;
    margin-right: 10px;
    font-size: 22px;
}

.highlight_list .tabs > ul:after {
    clear: both;
    content: '';
    display: table;
}

.highlight_list .tab_content {
    display: none;
    line-height: 30px;
    font-size: 14px;
    text-align: justify;
}

.highlight_list .tab_content.active {
    display: block
}

.neraby_tabs .tabs > ul {
    margin-bottom: 20px;
    background: #999;
    padding: 10px;
    border-radius: 5px;
}

.neraby_tabs .tabs > ul:after {
    clear: both;
    content: '';
    display: table;
}

.neraby_tabs .tabs > ul li {
    float: left;
    width: 20%;
    text-align: center;
    line-height: 35px;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500
}

.neraby_tabs .tabs > ul li:hover {
    color: #ccc
}

.neraby_tabs .tabs > ul li.active {
    color: #222;
    background: #fff;
}

.neraby_tabs .tab_content {
    display: none;
    margin-bottom: 20px;
}

.neraby_tabs .tab_content.active {
    display: block
}

.nearby_slider .swiper-slide {
    background-position: center;
    background-size: cover;
    height: 180px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

.nearby_slider .swiper-slide a {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
    opacity: 0;
    transition: 0.3s;
    visibility: hidden;
}

.nearby_slider .swiper-slide:hover a {
    visibility: visible;
    opacity: 0.9
}

.nearby_slider .swiper-slide a i {
    display: block;
    line-height: inherit;
    text-shadow: 0 0 4px rgb(0, 0, 0)
}

.nearby_slider .button-prev,
.nearby_slider .button-next {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s
}

.nearby_slider .button-next {
    left: auto;
    right: 0
}

.nearby_slider .swiper-container:hover .button-prev,
.nearby_slider .swiper-container:hover .button-next {
    opacity: 1;
    visibility: visible;
}

.nearby_slider .button-prev i,
.nearby_slider .button-next i {
    position: absolute;
    line-height: 40px;
    font-size: 30px;
    margin-top: -10px;
    top: 50%;
    left: 0;
    right: 0;
}

.local_guide_item {
    text-align: center;
    padding-top: 5px;
}

.local_guide_avatar {
    width: 140px;
    height: 140px;
    border-radius: 100%;
    border: 1px solid #ddd;
    margin: 0 auto 10px;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.05);
    transition: 0.3s
}

.local_guide_item:hover .local_guide_avatar {
    box-shadow: 0 0 0 5px rgba(51, 204, 204, 0.4)
}

.local_guide_avatar img {
    height: 100%;
    width: 100%;
    display: block;
    border-radius: 100%;
}

.local_guide_item .rates_star {
    margin: 0 auto 10px;
    float: none
}

.local_guide_item .local_guide_name a {
    font-size: 15px;
    font-weight: 600;
    line-height: 25px;
    color: #2d3055;
}

.local_guide_item .local_guide_name a:hover {
    color: #33cccc
}

#route_local_guides .button-prev,
#route_local_guides .button-next {
    position: absolute;
    left: 0;
    top: 50%;
    height: 40px;
    margin-top: -20px;
    width: 40px;
    text-align: center;
    z-index: 10;
    cursor: pointer;
    z-index: 10;
    font-size: 30px;
}

#route_local_guides .button-next {
    left: auto;
    right: 0
}

#route_local_guides .button-prev i,
#route_local_guides .button-next i {
    line-height: inherit;
    display: block;
}

.local_guide_item_box {
    padding: 100px 0 0;
    margin-top: 80px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    margin-bottom: 30px;
    position: relative;
    transition: 0.3s;
}

.local_guide_item_box:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.local_guide_item_detail_box {
    margin-top: 80px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    margin-bottom: 30px;
    transition: 0.3s;
}

.local_guide_item_detail {
    padding: 100px 0 0;
    position: relative;
}

.local_guide_image {
    position: absolute;
    top: -80px;
    width: 160px;
    height: 160px;
    right: 50%;
    margin-right: -80px;
    border-radius: 100%;
    border: 1px solid #ccc;
    padding: 5px;
    background: #fff;
    overflow: hidden;
}

.local_guide_image img {
    width: 150px;
    height: 150px;
    display: block;
    border-radius: 100%;
}

.local_guide_item_detail .rates_star,
.local_guide_item_box .rates_star {
    margin: 0 auto 15px;
    float: none
}

.local_guide_item_detail .local_guide_name,
.local_guide_item_box .local_guide_name {
    margin-bottom: 15px;
}

.local_guide_name h1 {
    font-size: 20px;
    text-align: center;
}

.local_guide_item_detail .local_guide_name a,
.local_guide_item_detail .local_guide_name span,
.local_guide_item_box .local_guide_name a {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    color: #2d3055;
    text-align: center;
    display: block;
}

.local_guide_item_detail .local_guide_name a:hover,
.local_guide_item_box .local_guide_name a:hover {
    color: #33cccc
}

.local_guide_info {
    background: #f7f7f7;
    padding: 10px;
    min-height: 110px;
}

.local_guide_info ul li {
    line-height: 30px;
}

.local_guide_info ul li:after {
    clear: both;
    content: '';
    display: table;
}

.local_guide_info ul li span:first-child {
    float: left;
    font-size: 13px;
    color: #333
}

.local_guide_info ul li span:last-child {
    float: right;
    font-size: 14px;
    font-weight: 500;
    color: #000
}

.local_guide_btns:after {
    clear: both;
    content: '';
    display: table;
}

.local_guide_btns {
    position: relative;
    border-radius: 0 0 3px 3px;
    overflow: hidden;
}

.local_guide_btn_col {
    float: left;
    width: 50%;
}

.local_guide_btns a {
    display: block;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s
}

a.local_guide_instant {
    color: #df2231
}

a.local_guide_more {
    color: #33cccc
}

a.local_guide_instant:hover {
    background: #df2231;
    color: #fff
}

a.local_guide_more:hover {
    background: #33cccc;
    color: #fff
}

.local_guide_item_detail_bio {
    padding: 10px;
    line-height: 25px;
    font-size: 14px;
    text-align: justify;
}

.local_guide_share > ul:after {
    clear: both;
    content: '';
    display: table;
}

.local_guide_share > ul {
    padding: 10px 10px 0 0;
}

.local_guide_share > ul > li {
    float: right;
    width: 30px;
    line-height: 30px;
    text-align: center;
    position: relative;
    margin-left: 5px;
}

.local_guide_share > ul > li:last-child {
    margin-left: 0
}

.local_guide_share > ul > li > a {
    display: block;
    line-height: inherit;
    font-size: 22px;
    color: #999
}

.local_guide_share > ul > li > ul {
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    border-radius: 15px;
    white-space: nowrap;
    height: 30px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s
}

.local_guide_share > ul > li:hover > ul {
    visibility: visible;
    opacity: 1
}

.local_guide_share > ul > li > ul > li {
    width: 30px;
    display: inline-block;
    height: 30px;
}

.local_guide_share > ul > li > ul > li a {
    line-height: 30px;
    text-align: center;
    display: block;
    font-size: 16px;
    color: #999
}

.local_guide_share > ul > li > ul > li a:hover {
    color: #2d3055
}

#booking_info {
    padding: 40px 0;
    background: #f7f7f7
}

.booking_panel {
    padding: 15px 15px 55px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    position: relative;
}

.local_guide_detail_info {
    margin-bottom: 20px;
}

.local_guide_detail_info ul li {
    font-size: 14px;
    font-weight: 500;
    color: #6c6c6c;
    line-height: 25px;
}

.local_guide_detail_info ul li div > span {
    line-height: 25px;
    display: inline-block;
}

.local_guide_detail_info ul li:after {
    clear: both;
    content: '';
    display: table;
}

.local_guide_name h1:after {
    content: url("../../../assets/frontend2/images/svg/confirmed.svg");
    position: absolute;
    width: 20px;
    height: 20px;
    top: 131px;
    right: 0px;
    background-repeat: no-repeat;
    background-position: center;
}

.local_guide_detail_info ul li .plate > div {
    float: right;
    font-size: 13px;
    color: #333;
    text-align: center;
}

.local_guide_detail_info ul li > span:last-child, .local_guide_detail_info ul li .plate, .local_guide_detail_info ul li .Specification {
    float: right;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    line-height: 25px;
    border-radius: 3px;
}

.local_guide_detail_info ul li .Specification span i {
    padding: 0 5px;
    font-weight: 800;
}

.local_guide_detail_info ul li .Specification span:nth-child(n+2) {
    margin-left: 10px;
}

.local_guide_detail_info ul li > .plate {
    width: 100px;
    border: 1px solid #484848;
}

.local_guide_detail_info ul li .plate div:last-child {
    width: 80%;
    border-right: 1px solid #484848;
}

.local_guide_detail_info ul li .plate div:last-child > span:nth-child(2) {
    font-weight: 500;
    font-size: 14px;
    color: #296363
}

.local_guide_detail_info ul li .plate div:first-child {
    width: 20%;
}

.booking_form label {
    font-weight: 500;
    line-height: 40px;
}

.DayPickerInput input {
    border-radius: 5px;
    padding: 7px 5px;
    width: 100%;
    font-size: 15px;
    text-align: center;
    border: 1px solid #33CCCC;
}

.DayPickerInput {

    width: 100%;

}

.localguide-input {
    border-radius: 5px;
    padding: 7px 5px;
    width: 100% !important;;
    font-size: 15px;
    text-align: center;
    border: 1px solid #33CCCC;
}

.localguide-input .select2-selection .select2-selection--single {
    border-width: 0 0 0 !important;;
}

.booking_form h5 {
    font-weight: 500;
    font-size: 15px;
    line-height: 40px;
}

.booking_form .form-group > span {
    line-height: 40px;
    display: inline-block;
    padding: 0 3px
}

.booking_form .form-group:after {
    clear: both;
    content: '';
    display: table;
}

.booking_form .form-group > .select2-container {
    margin-left: 5px;
}

.booking_form .form-group.half-selectbox-row {
    margin-right: -5px;
    padding-left: 30px;
    position: relative;
}

.booking_form .form-group.half-selectbox-row:before {
    position: absolute;
    left: 0;
    top: 0;
    content: '\f1ab';
    font-family: material-design;
    line-height: 40px;
    font-size: 25px;
    width: 30px;
    text-align: center;
    color: #33cccc
}

.booking_form .form-group .half-selectbox {
    float: left;
    width: 50%;
    padding: 0 5px;
}

.booking_form .form-group .half-selectbox .select2-container,
.booking_form .form-group .half-selectbox .select2picker {
    width: 100%
}

.add_more_destination {
    margin-bottom: 10px;
}

.add_more_destination a {
    display: inline-block;
    line-height: 15px;
    font-weight: 500;
    text-align: center;
    color: #33cccc;
}

.add_more_destination a:before {
    content: '\f278';
    font-family: material-design;
    float: left;
    font-size: 18px;
    margin-right: 5px;
    line-height: inherit;
}

.bookig_btn {
    border-radius: 0 0 5px 5px;
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f7f7f7;
}

.bookig_btn:after {
    clear: both;
    content: '';
    display: table;
}

.bookig_btn_col {
    float: left;
    width: 50%;
}

.bookig_btn .bookig_btn_instant,
.bookig_btn .bookig_btn_check {
    display: block;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s;
    width: 100%;
    padding: 0;
    border: none;
    background: transparent;
}

.bookig_btn .bookig_btn_instant {
    color: #df2231;
}

.bookig_btn .bookig_btn_check {
    color: #33cccc;
}

.bookig_btn .bookig_btn_instant:hover {
    background: #df2231;
    color: #fff;
    cursor: pointer;
}

.bookig_btn .bookig_btn_check:hover {
    background: #33cccc;
    color: #fff;
    cursor: pointer;

}

#local_guide_detail_slider {
    padding: 40px 0;
}

#local_guide_detail_slider .swiper-slide {
    background-position: center;
    background-size: cover;
    height: 220px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

#local_guide_detail_slider .swiper-slide a {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    z-index: 5;
    font-size: 25px;
    color: #fff;
    opacity: 0;
    transition: 0.3s;
    visibility: hidden;
}

#local_guide_detail_slider .swiper-slide:hover a {
    visibility: visible;
    opacity: 0.9
}

#local_guide_detail_slider .swiper-slide a i {
    display: block;
    line-height: inherit;
    text-shadow: 0 0 4px rgb(0, 0, 0)
}

#local_guide_detail_slider .button-prev,
#local_guide_detail_slider .button-next {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s
}

#local_guide_detail_slider .button-next {
    left: auto;
    right: 0
}

#local_guide_detail_slider .swiper-container:hover .button-prev,
#local_guide_detail_slider .swiper-container:hover .button-next {
    opacity: 1;
    visibility: visible;
}

#local_guide_detail_slider .button-prev i,
#local_guide_detail_slider .button-next i {
    position: absolute;
    line-height: 40px;
    font-size: 30px;
    margin-top: -10px;
    top: 50%;
    left: 0;
    right: 0;
}

.local_guide_expriences_form:after {
    clear: both;
    content: '';
    display: table;
}

.local_guide_expriences_form .checkbox_custom {
    float: left;
    width: 33.333333%
}

.local_guide_review_form:after {
    clear: both;
    content: '';
    display: table;
}

.local_guide_review_form .checkbox_custom {
    float: left;
    width: 33.33333%
}

#local_guide_expriences {
    padding: 40px 0
}

#local_guide_reviews {
    padding: 40px 0
}

#local_guide_reviews .form_review {
    overflow-x: hidden;
}

.form_exprences .form-group > label {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    display: block;
    margin-top: 8px;
}

.sights_result_item {
    padding: 10px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    margin-bottom: 30px;
    position: relative;
    transition: 0.3s
}

.sights_result_item:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.important_sight {
    position: relative;
    transition: 0.3s
}

.sights_home_item_image {
    height: 230px;
    border-radius: 3px;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;

}

.sights_home_item_image a:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    transition: 0.3s;
}

.image-sliders {
    width: 100%;
    height: 500px
}

.slider_title {
    left: 27%;

    position: absolute;
    bottom: 50%;
    color: #fff;
    /* max-width: 500px; */
    background: -webkit-linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: -moz-linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: -ms-linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: -o-linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    padding: 20px 130px 20px 20px;
    transition: all .9s;
}

.header-shadow {
    position: absolute;
    color: #fff;
    width: 100%;
    height: 100px;
    z-index: 1;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    padding: 20px;
    transition: all .9s;
}

.slider_title h1 {
    font-size: 20px;
}

.slider_title b {
    font-size: 20px;
}

.slider_title h2 {
    font-size: 17px;
    font-family: RobotoL;
}

.slider_title a {
    color: #fff;
}

.menu-item {
    text-align: center;
    border-radius: 10px 0 0 10px;
    cursor: pointer;
    border-right: 1px #ededed solid;

}

.menu-item:last-child {

    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    border-right: none;
}

.menu-item-more:hover > .more-items {
    display: block;
    margin-left: -15px

}

.more-items {
    background: #fff;
    width: 200px;
    display: none;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

}

.more-items h6 {
    padding: 20px;
    border-radius: 5px;
    text-align: left;
}

.more-items h6:hover, .header-nav__dropdown-secondary .dropdown-item:hover, .header-nav__dropdown-main .dropdown-item:hover {
    background: #ececec;

}

.disable-item {
    cursor: not-allowed;
    text-align: center;
    color: #888888;
    font-family: RobotoB;
}

.menu-item a, .header-nav .dropdown-item {
    color: #888888;
}

.header-nav .dropdown-item {
    padding: 10px
}

.header-nav .dropdown-item i {
    padding-right: 8px;
    display: inline-block !important
}

.header-nav .dropdown-menu {
    padding: 0px !important;
    width: 230px !important;
    margin-top: 23px;
}

.menu-item a {

    font-family: RobotoB;
}

.menu-item:hover {
    background: rgba(0, 0, 0, 0.05);
}

.menu-header {
    bottom: -65px;
    position: absolute;
    height: 125px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    left: 250px;
    right: 250px;
    border-radius: 10px;
    z-index: 5;
}

.sights_home_item_image img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.home-page-title {
    text-align: center;
    margin-top: 40px;
}

.home-page-title span {
    font-size: 1.2rem;
    color: #434343;
}

.slick-next:before, .slick-prev:before {
    color: rgba(51, 204, 204, 1) !important
}

.home-page-title h1 {
    font-size: 20px;
    color: #434343;
}

.custom-form {
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
    background: #fff;
    margin-bottom: 35px;
}

.custom-form .questionnaire {
    font-family: RobotoB;
    font-size: .9rem;
    color: #6c6c6c;
    margin-bottom: 5px;
    padding: 10px;
}

.custom-form .questionnaire input {
    font-family: RobotoB;
    font-size: .9rem;
    color: #6c6c6c;
    margin-left: 5px;
    width: auto;
}

.review-info label {
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    margin-bottom: 30px;
    border: 1px solid #cacaca;
    transition: .5s;
    font-family: Roboto !important;;

}

.ticket-card {
    background: #fff;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: all .5s ease-in-out;
}

.ticket-card .payment-details {

    text-align: center;
    width: 100%;
    display: grid;
    background: #ffcd00;
    margin-bottom: 15px;
    margin-bottom: 20px;

}

.ticket-card .payment-details span {
    width: 100%;
    padding: 2px;
    color: #555;
    font-size: 1rem;

}

.redirect-payment {
    margin-top: 25px;
    display: block;
}

.ticket-card .payment-items span:first-child {

    font-family: RobotoM;
    color: #555;

}

.ticket-card .payment-items span {

    font-size: 1rem;
    font-family: RobotoL;
    color: #555;
    padding: 5px;
}

.message {

    width: 100%;
    text-align: center;
    margin: 20px;
}

.message span {
    font-family: Roboto;
    font-size: 1.2rem;
    color: #555;
}

.message h5 {
    font-size: 1.2rem;
    font-family: Roboto;
    color: #cc0e00;
    margin-top: 10px;
}

.message h5.success {
    font-size: 1.2rem;
    font-family: Roboto;
    color: #3ccccc;
    margin-top: 10px;
}

.item-img-pay {
    background: url("../images/svg/payment.svg") no-repeat;
    background-position: center;
    background-size: contain;
    height: 250px;
}

.bus-review-info label {

    font-size: .9rem;
    padding: 10px;
    width: 100%;
    transition: .5s;
    font-family: Roboto !important;;

}

.bus-review-info label b {

    font-family: RobotoB;

}

.additional_item {
    min-height: 180px;
    padding: 10px;

}

.additional_item a {
    display: block;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    border: 1px solid #3cc;
    padding: 10px;
    border-radius: 2px;
    min-height: 180px;
}

.additional_item h2 {
    text-align: center;
    padding: 5px;
    margin: 0;
    font-size: .8rem;
    color: #666;
}

.additional_item.more a {

    border: 1px solid #ffcd00;
}

.additional_item img {
    width: 100%;
     height: 170px;
    object-fit: contain;
}

.bus-type-icon {
    background: url("../images/svg/bus_type.svg") no-repeat;
    padding: 5px 0px 0px 30px;
    background-size: 30px 30px;
    background-position: 0;
}

.option-icon {
    background: url("../images/svg/option.svg") no-repeat;
    padding: 10px 0 0 20px;
    background-size: 20px 20px;
    background-position: 0;
}

.company-name-icon {
    background: url("../images/svg/company_name.svg") no-repeat;
    padding: 5px 0px 0px 30px;
    background-size: 30px 30px;
    background-position: 0;
}

.terminal-icon {
    background: url("../images/svg/departure_terminal.svg") no-repeat;
    padding: 5px 0px 0px 30px;
    background-size: 30px 30px;
    background-position: 0;
}

.location-icon {
    background: url("../images/placeholder.png") no-repeat;
    padding: 5px 0px 0px 30px;
    background-size: 30px 30px;
    background-position: 0;
}

.time-icon {
    background: url("../images/svg/time.svg") no-repeat;
    padding: 5px 0px 0px 30px;
    background-size: 30px 30px;
    background-position: 0;
}

.mobile-number {
    background: url("../images/svg/mobile_black.svg") no-repeat;
    padding: 5px 0px 0px 30px;
    background-size: 30px 30px;
    background-position: 0;
}

.email-icon {
    background: url("../images/svg/emaile_black.svg") no-repeat;
    padding: 5px 0px 0px 30px;
    background-size: 30px 30px;
    background-position: 0;
}

.passport-icon {
    background: url("../images/svg/passport_black.svg") no-repeat;
    padding: 5px 0px 0px 30px;
    background-size: 30px 30px;
    background-position: 0;
}

.seats-icon {
    background: url("../images/svg/seats.svg") no-repeat;
    padding: 5px 0px 0px 30px;
    background-size: 30px 30px;
    background-position: 0;
}

.total-price-icon {
    background: url("../images/svg/total_price.svg") no-repeat;
    padding: 5px 0px 0px 30px;
    background-size: 30px 30px;
    background-position: 0;
}

.mastercard {
    background: url("../images/svg/MasterCard.svg") no-repeat;
    width: 25%;
    height: 50px;
    background-size: 75px;
    background-position: center;
}

.maestro {
    background: url("../images/svg/Maestero.svg") no-repeat;
    width: 25%;
    height: 50px;
    background-size: 75px;
    background-position: center;
}

.visa-card {
    background: url("../images/svg/Visa_card.svg") no-repeat;
    width: 25%;
    height: 50px;
    background-size: 75px;
    background-position: center;
}

.electron {
    background: url("../images/svg/Visa_Electron.svg") no-repeat;
    width: 25%;
    height: 50px;
    background-size: 75px;
    background-position: center;
}

@media screen and (max-width: 1199px) {
    .mastercard, .maestro, .visa-card, .electron {

        background-size: 60px;

    }
}

@media screen and (max-width: 990px) {
    .mastercard, .maestro, .visa-card, .electron {

        background-size: 45px;

    }
}

@media screen and (max-width: 767px) {
    .mastercard, .maestro, .visa-card, .electron {

        background-size: 75px;

    }
}

@media screen and (max-width: 430px) {
    .mastercard, .maestro, .visa-card, .electron {

        background-size: 60px;

    }
}

.bus-review-info .header {
    text-align: center;
    background: -webkit-linear-gradient(right, #249fc0, #3ccccc);
    background: -moz-linear-gradient(right, #249fc0, #3ccccc);
    background: -ms-linear-gradient(right, #249fc0, #3ccccc);
    background: -o-linear-gradient(right, #249fc0, #3ccccc);
    background: linear-gradient(right, #249fc0, #3ccccc);
    width: 100%;
}

.bus-review-info .header h5 {

    padding: 10px;
    margin-bottom: 5px;
    width: 100%;
    color: #fff !important;
    font-family: RobotoM !important;;

}

.yek-pay-icon {

    background: url("../images/Yekpay_ImgID1.png") no-repeat;
    width: 375px;
    background-size: contain;

}

.yek-pay-holder h3 {
    margin-left: 10px;
    color: #641772;
    font-size: 1.3rem;
    margin-bottom: 5px;
    font-family: RobotoM;
}

.center-btn-bus {
    margin-left: calc(50% - 100px);
    margin-top: 10px;
    margin-bottom: 10px;
}

.yek-pay-holder {
    display: flex;
}

.yek-pay-holder div {
    float: left;
}

.yek-pay-holder p {
    margin-left: 10px;

}

.notes {
    padding: 10px;
}

.notes h6 {
    color: #3ccc;
}

.notes ul li {
    padding-left: 2px;
    font-family: Roboto;
}

.notes ul li::before {
    content: ".";
    color: #3cc;
    font-weight: bold;
    font-size: 40px;
    line-height: 0.2;
    display: inline-block;
}

.notes ol li {
    list-style: none;
    float: left;
}

.notes ol {
    margin: 20px;
    display: flex;
}

.notes b {
    font-size: 1rem;
    color: #555;
    font-family: RobotoB;
}

.bus-review-info .header h5.details-icon {

    background: url("../images/svg/Bus_Ticket_white.svg") no-repeat;
    background-size: 50px 40px;
    background-position: 20px 4px;
}

.bus-review-info .header span {
    margin-right: 20px;
    position: absolute;
    color: #fff;
    font-family: RobotoB;
    font-size: .8rem;
    right: 15px;
    top: 25px;
}

.bus-review-info .header h5.bus-ticket-icon {

    background: url("../images/svg/bus_ticket_ic.svg") no-repeat;
    background-size: 50px 40px;
    background-position: 20px 4px;
}

.bus-review-info .header h5.passenger-icon {

    background: url("../images/svg/passenger_white.svg") no-repeat;
    background-size: 50px 40px;
    background-position: 20px 4px;
}

.bus-review-info .header h5.billing-icon {

    background: url("../images/svg/billing.svg") no-repeat;
    background-size: 50px 40px;
    background-position: 20px 4px;
}

.left-menu {

}

.left-menu li {
    margin-bottom: 10px;
    font-size: 1rem;
    padding: 10px;
    border: 1px #33CCCC solid;
    border-radius: 5px;
    cursor: pointer;
}

.left-menu .active {
    margin-bottom: 10px;

    color: #fff;
    font-size: 1rem;
    padding: 10px;
    background: #33CCCC;
    border-radius: 5px;
    cursor: pointer;
}

.review-info h5 {
    margin: 20px 0;
    background-color: #33CCCC;
    padding: 5px;
    color: #fff !important;
    font-family: RobotoM !important;;

}

.bus-review-info h6 {
    color: #555 !important;
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: RobotoM !important;
    font-size: .8rem;

}

.bus-review-info ul {
    color: #555 !important;
    font-family: Roboto !important;

}

.custom-form .Country {
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #cacaca;
    transition: .5s;
}

.custom-form h5 {
    font-family: RobotoB;
    margin-bottom: 15px;
    color: #404040;
}

.custom-form input {
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #cacaca;
    transition: .5s;

}

[class^="MuiFormControl-root"] {
    border-radius: 5px !important;
    padding: 10px !important;
    width: 100% !important;
    margin-bottom: 10px !important;
    border: 1px solid #cacaca !important;
    transition: .5s !important;
}

.jss4 {
    border-radius: 5px !important;
    padding: 10px !important;
    width: 100% !important;
    margin-bottom: 10px !important;
    border: 1px solid #cacaca !important;
    transition: .5s !important;
}

[class^="MuiInputBase-root"]:before {
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: none !important;
    pointer-events: none;
}

.jss42 {
    padding: 0 !important;
}

.jss201 .jss42 {
    padding: 12px !important;
}

.jss21:before {
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: none !important;
    pointer-events: none;
}

[class^="MuiIconButton-label"] {
    margin: 0 !important;
}

.jss827 {
    margin: 0 !important;
}

[class^="DateFormatInput-input"] {
    padding: 0 !important;
}

.jss3 {
    padding: 0 !important;
    color: rgba(0, 0, 0, 0.87) !important;

}

.custom-form hr {
    border-color: #33CCCC;
}

.custom-form select {
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    background: #fff;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #cacaca;
    transition: .5s;
}

.custom-form .select2-selection__rendered {
    border-radius: 5px;
    cursor: pointer;
    background: #fff;
    width: 100%;
    margin-bottom: 10px;
    transition: .5s;
}

.custom-form .select2-container--default .select2-selection--single {
    padding: 10px;
    height: 40px;
}

.visa-form .select2-selection__rendered {

    border: 1px solid #cacaca;
}

.booking_panel .select2-selection__rendered {

    border: 1px solid #33CCCC;
    border-radius: 5px;
}

.booking_panel .select2-container--default .select2-selection--single .select2-selection__rendered {

    border: 1px solid #33CCCC;
    border-radius: 5px;
}

.booking_panel .select2-container--default .select2-selection--single {
    border-width: 0 0 0;
}

.booking_panel .select2-container .select2-selection--single {
    height: 35px;
}

.visa-form .custom-form .select2-container--default .select2-selection--single {
    padding: 0px;
}

.custom-form label {
    font-family: RobotoB;
    font-size: .9rem;
    color: #6c6c6c;
    margin-bottom: 5px;

}

.custom-form input:focus, .custom-form select:focus {
    border: 1px solid #33CCCC;

}

.home-page-title .itemLink {
    background: #Ff9900;
    color: #fff;
    padding: 10px 50px;
    border-radius: 3px;
    font-size: .9rem;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.home-page-title .itemLink:hover {

    background: #Ff9900;
    padding: 10px 70px;

}

.sights_result_item_image {
    height: 230px;
    border-radius: 3px;
    overflow-y: hidden;
    position: relative;
    margin-bottom: 5px;
}

.sights_result_item_image a:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(51, 204, 204, 0.7) 100%);
    transition: 0.3s;
}

.sights_result_item:hover .sights_result_item_image a:before {
    opacity: 1;
}

.sights_result_item_image img {
    width: 100%;
    height: 100%;
    display: block;
}

.sights_result_share > ul:after {
    clear: both;
    content: '';
    display: table;
}

.sights_result_share > ul > li {
    float: right;
    width: 30px;
    line-height: 30px;
    text-align: center;
    position: relative;
    margin-left: 5px;
}

.sights_result_share > ul > li:last-child {
    margin-left: 0
}

.sights_result_share > ul > li > a {
    display: block;
    line-height: inherit;
    font-size: 22px;
    color: #999
}

.sights_result_share > ul > li > ul {
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    border-radius: 15px;
    white-space: nowrap;
    height: 30px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s
}

.sights_result_share > ul > li:hover > ul {
    visibility: visible;
    opacity: 1
}

.sights_result_share > ul > li > ul > li {
    width: 30px;
    display: inline-block;
    height: 30px;
}

.sights_result_share > ul > li > ul > li a {
    line-height: 30px;
    text-align: center;
    display: block;
    font-size: 16px;
    color: #999
}

.sights_result_share > ul > li > ul > li a:hover {
    color: #2d3055
}

.sights_result_item_time {
    padding-top: 25px;
    border-top: 1px solid #eee;
    position: relative;
    margin-top: 15px;
}

.sights_result_item_time > span {
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -60px;
    width: 120px;
    padding: 0 5px;
    background: #fff;
    text-align: center;
    color: #000;
    line-height: 20px;
    font-size: 13px;
}

.sights_result_item_time ul:after {
    clear: both;
    content: '';
    display: table;
}

.sights_result_item_time ul {
    margin: 0 -2.5px;
}

.sights_result_item_time ul li {
    float: left;
    width: 50%;
    padding: 0 2.5px
}

.sights_result_item_time ul li:nth-child(1),
.sights_result_item_time ul li:nth-child(2), .sights_result_item_time_placeHolder ul li:nth-child(1),
.sights_result_item_time_placeHolder ul li:nth-child(2) {
    margin-bottom: 20px;
}

.sights_result_item_time .sights_result_item_time_item {
    text-align: center;
    color: #333;
    border-radius: 2px;
    position: relative;
    border: 1px solid #ddd;
    min-height: 56px;
    padding: 12px 2px 2px;
}

.sights_result_item_time ul li span {
    display: block;
    margin-top: 2px;
}

.sights_result_item_time ul li span:first-child {
    position: absolute;
    color: #fff;
    left: 15px;
    top: -10px;
    line-height: 20px;
    right: 15px;
    border-radius: 10px;
    background: #249fc0;
    margin: 0
}

.sights_result_item_time ul li span.best {
    background: #f37c68;
    color: #ffffff;
}

.sights_result_item_detail ul li {
    position: relative;
    padding-left: 15px;
}

.sights_result_item_detail ul li:after {
    clear: both;
    content: '';
    display: table;
}

.sights_result_item_detail > ul > li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 6px;
    height: 6px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    transform: rotate(-45deg)
}

.sights_result_item_detail > ul > li span {
    line-height: 25px;
    display: block;
    color: #888;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sights_result_item_detail > ul > li span:first-child {
    color: #000;
    float: left;
    width: 100px;
}

.search_result_title_row {
    margin-bottom: 40px;
    border-bottom: 1px solid #eee;
}

.search_result_title_row h3 {
    font-size: 20px;
    line-height: 40px;
    display: inline-block;
    padding-right: 10px;
    border-bottom: 1px solid #33cccc;
    margin-bottom: -1px;
}

.search_result_title_row h1 {
    font-size: 20px;
    line-height: 40px;
    display: inline-block;
    padding-right: 10px;
    border-bottom: 1px solid #33cccc;
    margin-bottom: -1px;
}

.search_result_title_row h13 i {
    float: left;
    line-height: inherit;
    margin-right: 10px;
    font-size: 30px;
    color: #999
}

.search_result_title_row h3 i {
    float: left;
    line-height: inherit;
    margin-right: 10px;
    font-size: 30px;
    color: #999
}

#city_places_to_stay_result .place_stay_item,
#city_places_to_eat_result .place_eat_item {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    transition: 0.3s
}

#city_places_to_stay_result .place_stay_item:hover,
#city_places_to_eat_result .place_eat_item:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3)
}

#search_parallax {
    background: #fff;
    padding: 10px 0
}

#search_parallax.fixed {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
}

#search_parallax ul:after {
    clear: both;
    content: '';
    display: table;
}

#search_parallax ul {
    display: flex;
}

#search_parallax ul li {
    flex: 1;
    padding: 0 5px;
}

#search_parallax ul li a {
    display: block;
    line-height: 35px;
    font-size: 12px;
    font-weight: 500;
    color: #2d3055;
    border: 1px solid #eee;
    border-radius: 3px;
    padding: 0 5px;
}

.placeholder {
    width: 100%;
    height: 100%;
    background-color: #eee;
}

#search_parallax ul li a.active {
    border-color: #33cccc;
}

#search_parallax ul li a i {
    float: left;
    line-height: inherit;
    margin-right: 5px;
    font-size: 25px;
}

#search_parallax ul li a span {
    font-size: 14px;
    background: #eee;
    line-height: 20px;
    padding: 0 10px;
    border-radius: 10px;
    color: #666;
    float: right;
    margin-top: 7.5px;
}

.color-effect {
    height: 100%;
    background-color: #eee;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 40%, rgba(255, 255, 255, 0) 80%);
    background-repeat: no-repeat;
    border-radius: 3px;
    animation-fill-mode: forwards;
    background-position: -1000px 0;
    animation-timing-function: linear;
    animation-name: hi;
    animation-duration: 2s;
    animation-iteration-count: infinite;

}

@keyframes hi {
    from {
        background-position: -1000px 0;
    }
    to {
        background-position: 1000px 0;
    }
}

.hotel_item.hotel_item_placeHolder,
.hotel_item.hotel_item_placeHolder:hover,
.local_guide_item_box_placeHolder,
.local_guide_item_box_placeHolder:hover,
.local_guide_item_detail_box_placeHolder,
.local_guide_item_detail_box_placeHolder:hover,
.sights_result_item_placeHolder,
.sights_result_item_placeHolder:hover {
    box-shadow: none;
    border-color: #eee
}

.hotel_item_img_placeHolder {
    float: left;
    width: 300px;
    height: 230px;
    margin-right: 10px;
    border-radius: 3px;
    position: relative;
}

.hotel_item_rates_placeHolder {
    width: 133px;
    height: 25px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 5;
}

.hotel_item_detail_placeHolder {
    float: left;
    width: 388px;
    height: 230px;
    position: relative;
    padding-right: 10px;
}

.hotel_item_detail_placeHolder ul, .route_item_detail_placeHolder ul {
    width: 380px;
    max-width: 100%;
}

.hotel_item_detail_placeHolder ul li, .route_item_detail_placeHolder ul li {
    height: 25px;
    margin: 5px 0;
    max-width: 100%;
}

.hotel_item_detail_placeHolder ul li:nth-child(1),
.route_item_detail_placeHolder ul li:nth-child(1) {
    width: 370px;
}

.hotel_item_detail_placeHolder ul li:nth-child(2),
.route_item_detail_placeHolder ul li:nth-child(2),
.sights_result_item_detail_placeHolder ul li:nth-child(2) {
    width: 300px;
}

.hotel_item_detail_placeHolder ul li:nth-child(3),
.route_item_detail_placeHolder ul li:nth-child(3),
.sights_result_item_detail_placeHolder ul li:nth-child(3),
.sights_result_item_detail_placeHolder ul li:nth-child(5),
.route_item_detail_placeHolder ul li:nth-child(5),
.hotel_item_detail_placeHolder ul li:nth-child(5	) {
    width: 250px;
}

.sights_result_item_detail_placeHolder ul li:nth-child(1),
.sights_result_item_detail_placeHolder ul li:nth-child(4),
.route_item_detail_placeHolder ul li:nth-child(4),
.hotel_item_detail_placeHolder ul li:nth-child(4) {
    width: 320px;
}

.hotel_item_review_box_placeHolder {
    width: 378px;
    height: 121px;
    margin-top: 15px;
    padding-top: 15px;
    position: relative;
}

.hotel_item_content_placeHolder {
    float: right;
    width: 430px;
    height: 230px;
    position: relative;
    padding-top: 25px;
}

.hotel_item_btn_placeHolder {
    position: absolute;
    height: 40px;
    display: none;
}

.hotel_item_destinations_placeHolder {
    padding-top: 15px;
    position: relative;
    margin-top: 10px;
}

.hotel_item_destinations_placeHolder ul li {
    width: calc(50% - 5px);
    height: 25px;
    margin: 5px 5px 5px 0;
    float: left;
}

.hotel_item_time_placeHolder, .hotel_item_times_placeHolder {
    width: 430px;
    height: 82px;
    padding-top: 25px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
}

.hotel_item_times_placeHolder {
    padding-top: 20px;
}

.hotel_item_time_placeHolder ul li, .hotel_item_times_placeHolder ul li {
    float: left;
    width: calc(25% - 5px);
    height: 56px;
    margin-right: 5px;
}

.city_art_placeHolder, .city_menu_list_placeHolder {
    height: 385PX;
}

.sec_title_placeHolder {
    height: 80px;
}

.food_drink_map_placeHolder {
    height: 450px;
}

#nearby_map {
    height: 450px;
}

.place_stay_name_placeHolder, .play_stay_price_placeHolder, .place_stay_rate_placeHolder {
    height: 27.5px;
}

.place_stay_name_placeHolder {
    width: 225px;
    margin-bottom: 5px;
}

.play_stay_price_placeHolder {
    width: 165px;
}

.place_stay_info_header_placeHolder ul {
    margin-top: 5px;
}

.place_stay_info_header_placeHolder ul li {
    height: 27.5px;
}

.place_stay_info_header_placeHolder ul li:first-child {
    width: 70%;
    margin-bottom: 5px;
}

.swiper-div_placeHolder {
    float: left;
    width: calc(50% - 5px);
    height: 420px;
}

.local_guide_image.local_guide_image_placeHolder {
    border-radius: 100%;
}

.swiper-div_placeHolder:first-child {
    margin-right: 5px;
}

.local_guide_rate_and_name_placeHolder {
    height: 60px;
    margin-bottom: 15px;
}

.local_guide_info_placeHolder {
    min-height: 110px;
    border-bottom: 1px solid #c6c6c6;
}

.local_guide_btn_col_placeHolder {
    height: 40px;
}

.local_guide_btn_col_placeHolder:first-child {
    border-right: 1px solid #c6c6c6;
}

.local_guide_item_detail_placeHolder {
    margin-top: 100px;
    margin-bottom: 15px;
    margin-left: 10px;
    margin-right: 10px;
    height: 60px;
}

.local_guide_share_placeHolder {
    margin: 10px 10px 0 0;
    min-height: 30px;
}

.local_guide_share_placeHolder div, .sights_result_share_placeHolder div {
    width: 20%;
    height: 30px;
    float: right;
}

.local_guide_item_detail_bio_placeHolder {
    margin: 10px;
    height: 140px;
}

#booking_info_placeHolder .col-md-5 div.color-effect, #booking_info_placeHolder .col-md-7 div.color-effect {
    height: 550px;
}

#booking_info_placeHolder .col-md-5 div.color-effect {
    margin-right: 5px;
}

.rout_detail_image_placeHolder {
    height: 400px;
}

.route_stay_btns_placeHolder {
    width: 65px;
    height: 30px;
}

.route_item_locations_placeHolder ul li {
    float: left;
    width: calc(33.333% - 5px);
    height: 27.5px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.route_item_locations_placeHolder ul li:nth-child(n+4) {
    margin-bottom: 0px;
}

.route_item_detail_placeHolder {
    margin-bottom: 15px;
}

.hotel_item_times_placeHolder {
    width: auto;
}

.sights_result_item_image_placeHolder {
    height: 230px;
    margin-bottom: 5px;
}

.sights_result_share_placeHolder div {
    height: 27.5px;
    margin-bottom: 5px;
}

.sights_result_item_detail_placeHolder ul li {
    height: 22.5px;
    margin-bottom: 5px;
}

.sights_result_item_time_placeHolder {
    height: 132px;
    margin-top: 25px;
}

.sights_result_item_time_placeHolder ul li {
    float: left;
    height: 56px;
    width: calc(50% - 5px);
    margin-right: 5px;
}

.sights_result_item_detail_placeHolder ul {
    max-width: 100%;
}

.sights_result_item_detail_placeHolder ul li {
    max-width: 100%;
}

.sights_result_share_placeHolder {
    min-height: 27.5px;
    margin-bottom: 5px;
}

.spin {
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 1300px) {
    .menu-header .menu-item {
        padding: 0px !important
    }

    .menu-header {
        height: 106px;

    }

    .header__category {
        margin-top: 0px !important;
        margin-bottom: 5px !important
    }
}

@media (max-width: 1200px) {
    /* .tabs-inner .nav-tabs{
        width: 68% !important
    } */
    .hotel_item_content, .hotel_item_content_placeHolder {
        width: 100%;
        height: auto;
        padding: 0
    }

    .hotel_item_time > span {
        position: absolute;
        top: -10px;
        left: 50%;
        margin-left: -60px;
        width: 120px;
        padding: 0 5px;
        background: #fff;
        text-align: center;
        color: #000;
        line-height: 20px;
        font-size: 13px;
    }

    .hotel_item_review_box_placeHolder {
        width: auto;
    }

    .sight_list .hotel_item_content {
        width: auto;
    }

    .hotel_item_destinations, .hotel_item_destinations_placeHolder {
        float: left;
        width: 300px
    }

    .hotel_item_time, .hotel_item_time_placeHolder {
        float: right;
        width: 598px;
        position: relative;
        margin-top: 10px;
    }

    .rout_detail_content .hotel_item_time {
        float: none;
        width: auto;
        position: absolute;
    }

    .hotel_item_detail, .hotel_item_detail_placeHolder {
        width: 598px;
    }

    .sight_list .hotel_item_detail {
        height: 138px;
    }

    .place_stay_content .place_stay_content_col_left {
        width: 50%
    }

    .place_stay_content .place_stay_content_col_right {
        width: 50%;
    }
}

.header-nav__caret {
    padding-left: 7px;
    font-size: 20px
}

.header-nav__dropdown-secondary .dropdown-item:active, .header-nav__dropdown-main .dropdown-item:active {
    background-color: transparent !important;
    color: #444;
    font-family: Roboto;

}

.dropdown-item--sm-size {
    font-size: var(--font-size2)
}

.header-nav__search {
    font-size: 30px !important;
    text-align: right;
    color: #fff
}

.container-header {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.changebg .navbar-brand img {
    content: url("../images/logo-B.svg");
}

@media (min-width: 992px) {

    .homepage .container-header {
        background-color: rgba(0, 0, 0, 0.6) !important;

    }

    .homepage .container-header.changebg, .otherpage .container-header {
        background-color: #fff !important;

        -webkit-transition: background-color 200ms linear;
        -ms-transition: background-color 200ms linear;
        transition: background-color 200ms linear;

    }

    .homepage .changebg .header-nav .nav-link, .otherpage .header-nav .nav-link {
        color: #444 !important
    }

    .homepage main {
        margin-top: 0px !important;
    }

    .otherpage main {
        margin-top: 73px
    }

    .header-nav {
        padding: 10px 5px
    }

    .header-nav__search {
        padding: 0;
        position: relative;

        z-index: 2
    }

    .header-nav__link {
        padding: 0 0 0 8px !important;
        font-family: Roboto;

    }

    .header-nav .header-nav__link:after {
        border-right: 1px solid #ccc;
        content: '';
        display: inline-block;
        height: 10px;

    }

}

.header-nav .nav-item:last-child a:after {
    height: 0 !important;

}

.no-divider:after {
    height: 0 !important;
}

/* .container-header{

       position: fixed;
       z-index: 100;
   } */
.btn-IR-gray {
    line-height: 26px !important;
    background: #fff !important;
    color: #444 !important;
    border: 2px solid #ccc;
    width: 200px
}

.btn-IR-gray:hover {
    background: #ccc
}

.header-nav__dropdown-main .header-nav__link:after {

    width: 0px;
    height: 0;
    padding: 0;
    border: 0;
}

.blackcolor {
    color: #444 !important
}

.noborder {
    border: none !important
}

.booking_button:hover {
    background: #2db0b0;
}

.booking_button {
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    margin-top: 7px;
    background: #3cc;
    border: 1px solid #fff;
}

.bg-lightgray {
    background-color: var(--IR-lightgray)
}

:root {
    --IR-green-color: #00a680;
    --IR-blue-color: #3cc;
    --IR-orange-color: #Ff9900;
    --IR-gray-text: #757474;
    --IR-lightgray: #f8f8f8;
    --IR-darkgray: #757373;
    --font-size1: 13px;
    --font-size2: 16px;
    --font-size3: 18px;
    --font-size4: 20px;
    --font-size5: 25px;
    --font-size6: 30px;
    --font-size7: 35px;
    --font-size8: 40px;
}

.small_text {
    font-size: var(--font-size2);
    line-height: 15px
}

.IR-background {
    background-color: var(--IR-blue-color)
}

.btn-disable-hover:hover {
    color: #fff;
    background-color: #5a6268 !important;
    border-color: #545b62;

}

.IR-btn-hover:hover {
    color: #fff;
    background-color: #3d9e9e;
}

.header-nav .header-nav__link:after {
    padding: 0 2px !important
}

@media (min-width: 992px) {
    .header-nav .nav-link:hover {
        color: var(--IR-blue-color) !important
    }

    .header-nav .nav-link, .header-nav .dropdown-item {
        font-size: var(--font-size1)
    }

    :root {
        --IR-green-color: #00a680;
        --IR-blue-color: #3cc;
        --font-size1: 13px;
        --font-size2: 16px;
        --font-size3: 18px;
    }

    .header-nav .header-nav__link:after {
        padding: 0 2px !important
    }

    @media (min-width: 992px) {
        .header-nav .nav-link:hover {
            color: var(--IR-blue-color) !important
        }

        .header-nav .nav-link, .header-nav .dropdown-item {
            font-size: var(--font-size1)
        }

        .navbar-nav.header-nav__main-item {
            margin-left: 1% !important
        }
    }

    @media (min-width: 1224px) {

        .header-nav .nav-link, .header-nav .dropdown-item {
            font-size: var(--font-size2)
        }

    }
    @media (min-width: 1500px) {
        .header-nav .header-nav__link:after {
            padding: 0 5px !important
        }

        .header-nav .nav-link {
            font-size: var(--font-size3)
        }
    }

    .navbar-nav.header-nav__main-item {
        margin-left: 1% !important
    }
}

@media (min-width: 1224px) {

    .header-nav .nav-link, .header-nav .dropdown-item {
        font-size: var(--font-size2)
    }

}

@media (min-width: 1500px) {
    .header-nav .header-nav__link:after {
        padding: 0 5px !important
    }

    .header-nav .nav-link {
        font-size: var(--font-size3)
    }
}

@media (max-width: 991px) {
    .animate_box_title {
        top: 12%
    }

    .homepage .navbar-brand img {
        content: url("../images/logo-B.svg") !important;
    }

    .container-header {
        background-color: #fff
    }

    .otherpage main {
        margin-top: 55px;
    }

    .header-nav .dropdown-item i {
        display: none !important
    }

    .header-nav__search {
        line-height: 42px;
        position: absolute;
        top: 0;
        right: 0;

    }

    .header-nav__search .nav-link {
        color: #444 !important
    }

    .header-nav .dropdown-menu {
        width: 100% !important;
        border: 0;
        box-shadow: unset;
        margin-top: 0px !important
    }

    .header-nav__main-item {
        margin-left: 0px !important
    }

    .header-nav .navbar-brand {

        position: relative;
        left: -6%;
    }

    .header-nav .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    .header-nav .navbar-toggler[aria-expanded="true"] span {
        background-image: none

    }

    .header-nav .navbar-toggler[aria-expanded="true"] span:after {
        content: '\F136';
        font-family: 'Material-Design';
        vertical-align: middle;
        line-height: 30px;
        font-size: 30px;
        color: var(--IR-blue-color)
    }

    .container-header {
        padding: 0px !important;
        max-height: 100vh;
        /* overflow-y: auto */

    }

    .header-nav {
        padding: 0px;
    }

    .header-nav__main-item {
        margin-top: 0px !important
    }

    .header-nav .navbar-collapse {
        padding: 0 14px;
        background-color: #fff;
        z-index: 11;
    }

    .header-nav .navbar-collapse .nav-link {
        color: #444 !important
    }

    .mx-lg-auto {
        margin: 0 auto
    }

    /* .header-slider {
        top: 60px !important
    } */
    header nav {
        display: none;
    }

    header .header_right_menu {
        margin: 0
    }

    header .header_right_menu ul li.menu_toggle {
        display: block;
    }

    header .logo {
        left: 15px;
        margin: 0;
        height: 50px;
        width: 50px;
    }

    .logoMain {
        left: 15px;
        top: 35px;
        margin: 0;
        height: 50px;
    }

    .search_result {
        left: 100px;
        right: 100px;
    }

    #search_wrapper .search_form {
        margin-left: 100px;
        margin-right: 100px;
    }

    .hotel_item_detail, .hotel_item_detail_placeHolder {
        padding-top: 30px;
        width: 358px;
        height: auto;
    }

    .hotel_item_rates, .hotel_item_rates_placeHolder {
        width: 358px;
    }

    .hotel_item_destinations,
    .hotel_item_destinations_placeHolder,
    .hotel_item_time,
    .hotel_item_time_placeHolder {
        width: auto;
        float: none
    }

    .hotel_item_detail_placeHolder ul li:nth-child(1) {
        width: 350px;
    }

    .place_stay_content .place_stay_content_col_left,
    .place_eat_content .place_eat_content_col_left {
        width: auto;
        float: none
    }

    .place_stay_content .place_stay_content_col_right,
    .place_eat_content .place_eat_content_col_right {
        width: auto;
        float: none;
        padding: 0;
        border-left: none
    }

    .place_stay_overview {
        float: none;
        width: auto;
    }

    .place_stay_overview {
        float: none;
        width: auto;
    }

    .place_stay_content,
    .place_eat_content {
        padding-bottom: 70px;
    }

    .place_stay_info,
    .place_eat_info {
        padding: 5px 0
    }

    .place_stay_info ul li,
    .place_eat_info ul li {
        line-height: 25px;
    }

    .place_stay_info ul li i,
    .place_eat_info ul li i {
        position: static;
        display: block;
        line-height: inherit;
    }

    .place_stay_content h2,
    .place_eat_content h2 {
        float: none
    }

    .place_eat_time {
        float: none;
    }

    .highlight_list .tabs > ul li {
        width: 33.3333%
    }

    .rout_detail_content {
        height: auto
    }

    .rout_detail_content .hotel_item_time {
        position: relative;
        margin-top: 25px;
    }

    .rout_detail_image {
        margin-bottom: 15px;
    }

    .sight_list .hotel_item_content {
        width: 100%
    }

    .hotel_item_times_placeHolder {
        position: static;
    }

    .route_stay_btns_placeHolder {
        top: 10px;
        right: 15px;
    }

    .rout_detail_image_placeHolder {
        margin-bottom: 10px;
    }

    #search_parallax ul {
        display: block;
    }

    #search_parallax ul li {
        width: 33.33333%;
        float: left;
        margin-bottom: 10px;
    }
}

.bold_title-text {
    font-size: var(--font-size2);
    color: #555555;
}
.conainer-main-hotel h1{
    font-size: var(--font-size3);
}

@media (max-width: 768px) {
    .bold_title-text-responsive {
        font-size: var(--font-size2)
    }
    .conainer-main-hotel h1{
        font-size: 25px;
    }

    .animate_box_title h1 {
        font-size: 30px;
    }

    .animate_box_title h2 {
        font-size: 23px;
    }

    #header-box {
        height: 100vh
    }

    #icon_info_box .container {
        max-width: 750px !important
    }

    .featured-item img {
        width: 45px !important
    }

    .featured-item .icon_info_item {
        width: calc(100% - 45px) !important;
        text-align: left;
        margin-left: 27px;
    }

    #search_wrapper .search_form input {
        line-height: 52px
    }

    #search_wrapper .search_form input {
        line-height: 52px
    }

    .search_result {
        left: 15px;
        right: 15px;
    }

    #search_wrapper .search_form {
        margin-left: 15px;
        margin-right: 15px;
    }

    #sights_slider .swiper-slide a span {
        bottom: 15px;
    }

    #sights_slider .swiper-container,
    #routes_slider .swiper-container {
        padding: 0 40px;
    }

    #sights_slider .swiper-container .button-prev,
    #sights_slider .swiper-container .button-next,
    #routes_slider .swiper-container .button-prev,
    #routes_slider .swiper-container .button-next {
        opacity: 1;
        visibility: visible;
    }

    .search_result_item_col_4,
    .search_result_item_col_3,
    .search_result_item_col_2 {
        width: 50%;
    }

    .sec_title {
        padding: 0 40px;
    }

    .filter_item {
        float: none;
        margin: 0 0 15px;
    }

    .filter_item.opened .filter_title {
        border-radius: 3px 3px 0 0
    }

    .filter_item .filter_content {
        visibility: visible;
        opacity: 1;
        display: none;
        position: static;
        top: 0;
    }

    .filter_item.opened .filter_content {
        display: block;
        width: auto;
        box-shadow: none;
        border: 1px solid #ddd;
        border-top: none;
        border-radius: 0 0 3px 3px;
    }

    .filter_item .filter_title:active {
        transform: scale(1);
    }

    .filter_item .filter_content:before {
        display: none
    }

    body.filter_fix {
        padding-bottom: 50px;
    }

    #page_filter {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: none;
        z-index: 99;
        display: none;
        padding-bottom: 60px;
    }

    #page_filter.opened {
        display: block;
        overflow-y: auto;
    }

    #page_filterSight {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: none;
        z-index: 99;
        display: none;
        padding-bottom: 60px;
    }

    #page_filterSight.opened {
        display: block;
        overflow-y: auto;
    }

    .filter_item .filter_content_box {
        max-height: 100%
    }

    #filter_toggle,
    #filter_close {
        display: block;
    }

    body.filter_fix {
        padding-top: 0;
    }

    .hotel_item {
        padding-bottom: 60px;
    }

    .hotel_item_btn {
        right: 10px;
        left: 10px;
        bottom: 10px;
        transition: bottom 0.3s;
    }

    .hotel_item_btn_placeHolder {
        display: block;
        right: 10px;
        left: 10px;
        bottom: 10px;
    }

    .hotel_item_btn a {
        border-radius: 3px;
    }

    .hotel_item:hover .hotel_item_btn {
        bottom: 10px;
    }

    .hotel_item_image, .hotel_item_img_placeHolder {
        width: auto;
        float: none;
        margin: 0 0 15px;
        position: static;
    }

    .hotel_item_rates, .hotel_item_rates_placeHolder {
        position: static;
        width: auto;
        margin-bottom: 10px;
    }

    .hotel_item_detail, .hotel_item_detail_placeHolder {
        width: auto;
        padding: 0;
        float: none;
        height: auto;
    }

    .hotel_item_content, .hotel_item_content_placeHolder {
        float: none
    }

    .top_place {
        padding: 0;
    }

    .top_place_image {
        position: static;
        width: auto;
        height: 200px;
        border-radius: 5px 5px 0 0
    }

    #city_parallax.fixed {
        position: static;
        box-shadow: none
    }

    #route_parallax ul,
    #city_parallax ul,
    #search_parallax ul {
        display: block;
    }

    #route_parallax ul li,
    #city_parallax ul li,
    #search_parallax ul li {
        width: 33.3333%;
        float: left;
    }

    body.city_parallax_fix,
    body.route_parallax_fix,
    body.search_parallax_fix {
        padding-top: 0
    }

    .city_art, .city_art_placeHolder {
        margin-bottom: 15px;
    }

    #hotel_detail_slider .swiper-slide a span {
        bottom: 15px;
    }

    .hotel_amenities,
    .hotel_overview {
        margin-bottom: 15px;
    }

    .review_modal_btn {
        padding: 30px 0
    }

    .comment_item {
        padding-left: 80px
    }

    .comment_avatar {
        width: 70px;
    }

    .comment_avatar img {
        width: 70px;
        height: 70px;
    }

    .comment_images ul li {
        padding: 2px;
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .comment_images ul li a:after {
        line-height: 50px
    }

    .comment_images ul li a img {
        width: 80px;
        height: 50px;
    }

    .neraby_tabs .tabs > ul li {
        width: 50%
    }

    .highlight_list .tabs > ul li {
        width: 50%
    }

    .route_item_locations ul li {
        width: 50%
    }

    .route_item_locations_placeHolder ul li {
        width: calc(50% - 5px);
    }

    .route_item_locations_placeHolder ul li:nth-child(n+4) {
        margin-bottom: 5px;
    }

    #route_parallax.fixed,
    #search_parallax.fixed,
    #city_parallax.fixed {
        position: static;
        box-shadow: none
    }

    #route_detail_slider .button-prev,
    #route_detail_slider .button-next,
    .nearby_slider .swiper-container .button-prev,
    .nearby_slider .swiper-container .button-next,
    #city_overview_slider .swiper-container .button-prev,
    #city_overview_slider .swiper-container .button-next,
    #hotel_detail_slider .button-prev,
    #hotel_detail_slider .button-next {
        opacity: 1;
        visibility: visible;
        top: 50%;
        bottom: auto;
        height: 40px;
        line-height: 40px;
        margin-top: -20px;
    }

    #route_detail_slider .button-prev i,
    #route_detail_slider .button-next i,
    .nearby_slider .swiper-container .button-prev i,
    .nearby_slider .swiper-container .button-next i,
    #city_overview_slider .swiper-container .button-prev i,
    #city_overview_slider .swiper-container .button-next i,
    #hotel_detail_slider .button-prev i,
    #hotel_detail_slider .button-next i {
        position: static;
        margin: 0
    }

    #city_overview_slider .swiper-slide a span {
        bottom: 15px;
    }

    .important_info_table {
        padding-left: 120px;
    }

    .important_info_table:after {
        clear: both;
        content: '';
        display: table;
    }

    .important_info_table_wrapper {
        overflow-x: auto;
        white-space: nowrap;
    }

    .important_info_table_header {
        position: absolute;
        width: 100px;
        left: 0;
        top: 0;
    }

    .important_info_table_row {
        display: inline-block;
        width: 150px;
        padding: 0 5px;
    }

    .important_info_table_row ul li,
    .important_info_table_header ul li,
    .important_info_table_row ul li.dbl {
        float: none;
        width: auto;
    }

    .important_info_table_row ul li.dbl span,
    .important_info_table_row ul li.dbl span:first-child,
    .important_info_table_row ul li.dbl span:last-child {
        float: none;
        width: auto;
        display: block;
        border-radius: 15px;
        margin: 5px 0 10px;
        line-height: 30px;
    }

    .important_info_table_row ul li.dbl span:last-child {
        line-height: 28px;
        margin-bottom: 5px
    }

    .view_map {
        display: block;
        line-height: 40px;
        height: auto;
        width: auto;
    }

    #search_parallax ul li {
        margin-bottom: 5px;
    }

    .place_stay_rate_placeHolder {
        margin-top: 5px;
    }

    .swiper-div_placeHolder {
        width: 100%;
    }

    .swiper-div_placeHolder:first-child {
        margin-right: 0px;
        display: none;
    }

    .route_stay_btns_placeHolder {
        display: none;
    }

    #search_parallax ul li {
        width: 50%;
    }

    .page_header_filter_col:after {
        clear: both;
        content: '';
        display: table;
    }

    .page_header_filter_col {
        margin-bottom: 10px;
        float: none
    }

    .page_header_filter_col .select2picker {
        float: right;
    }

    .page_header_filter_col .select2-container {
        float: right;
    }
}

@media (max-width: 600px) {

    #page_header .page_header {
        padding: 15px 0;
        text-align: left;
    }

    #page_header .page_header:before,
    #page_header .page_header:after {
        display: none
    }

    .local_guide_expriences_form .checkbox_custom,
    .local_guide_review_form .checkbox_custom {
        float: none;
        width: auto;
    }

}

@media (max-width: 480px) {
    .local_guide_item_detail_bio_placeHolder {
        height: 200px;
    }

    .image-sliders {
        min-height: 570px;
    }

    .sec_title h3 {
        font-size: 16px;
    }

    .search_result_item_col_4,
    .search_result_item_col_3,
    .search_result_item_col_2 {
        float: none;
        width: auto;
    }

    .top_rates_item {
        width: calc(50% - 15px);
        margin-bottom: 15px;
    }

    .top_rates_item:nth-child(even) {
        margin-left: 15px;
    }

    #page_header h2 {
        font-size: 18px;
    }

    .hotel_item_time ul li {
        width: 50%
    }

    .hotel_item_time_placeHolder ul li {
        width: calc(50% - 5px)
    }

    .hotel_item_destinations ul li, .hotel_item_destinations_placeHolder ul li {
        width: auto;
        float: none
    }

    .hotel_item_time_placeHolder ul {
        margin: 0 -5px;
    }

    .hotel_item_time ul li:nth-child(1),
    .hotel_item_time ul li:nth-child(2),
    .hotel_item_time_placeHolder ul li:nth-child(1),
    .hotel_item_time_placeHolder ul li:nth-child(2) {
        margin-bottom: 20px;
    }

    .hotel_item_time_placeHolder ul li:nth-child(2),
    .hotel_item_time_placeHolder ul li:nth-child(4) {
        margin-right: 0;
    }

    .place_stay_content,
    .place_eat_content {
        padding-bottom: 110px;
    }

    .place_stay_info ul li,
    .place_eat_info ul li {
        float: none;
        width: auto;
        text-align: left;
        line-height: 30px;
    }

    .place_stay_info ul li i,
    .place_eat_info ul li i {
        float: left;
        margin-right: 5px;
    }

    .view_more a {
        display: block;
        padding: 0 15px;
    }

    .city_menu_list ul li a:after {
        width: 20px;
    }

    .city_menu_list ul li a i {
        width: auto;
        margin-right: 10px;
    }

    .form_review .inline_label {
        margin-right: 5px
    }

    .neraby_tabs .tabs > ul li {
        width: auto;
        float: none
    }

    .highlight_list .tabs > ul li {
        width: auto;
        float: none
    }

    .route_item_locations ul li, .route_item_locations_placeHolder ul li {
        width: auto;
        float: none
    }

    #route_parallax ul li,
    #city_parallax ul li,
    #search_parallax ul li {
        width: 50%
    }

    .rout_detail_image img {
        height: 250px;
    }

    #search_parallax ul li a span {
        background: transparent;
        color: #999;
        line-height: inherit;
        padding: 0;
        float: none;
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        margin-left: 3px;
    }

    #search_parallax ul li {
        width: auto;
        float: none
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

/*breadCrumb*/

#breadcrumbs {
    padding: 10px 0
}

.sights-header-title {
    font-size: 1.1rem;
    color: #626262;
    padding-bottom: 0.5rem;
}

#breadcrumbs ul li {
    float: left;
    position: relative;
    list-style-type: none;
}

#breadcrumbs ul li a, #breadcrumbs ul li span {
    display: inline-block;
    line-height: 30px;
    font-size: 14px;
    font-weight: 500;
    color: #626262;
    margin: 0 16px 0 10px;
}

#breadcrumbs ul li span {
    color: #343434;
}

#breadcrumbs ul li a:after {
    content: '\f2fb';
    font-family: material-design;
    position: absolute;
    right: 0;
    top: 6px;
    font-size: 20px;
    color: #ccc;
    line-height: 20px;
}

.avatar {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);

}

.content-profile {
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);

}

.hor-inline-menu li {
    float: left;
    padding: 10px;
}

.form-custom input, .form-custom textarea, form-custom select {
    width: 100%;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

}

.img-circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 5px;
}

#file-avatar, #file-nature, #file-culture, #file-insurance {
    margin: 10px;
    opacity: 0;
}

.containerCh {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-bottom: 10px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.containerCh input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.containerCh:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCh input:checked ~ .checkmark {
    background: -webkit-linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    background: -moz-linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    background: -ms-linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    background: -o-linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    background: linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    border-radius: 2px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.containerCh input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.containerCh .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.modal-team span {

    font-size: .9rem;
}

.modal-team p {
    text-align: justify;
    font-size: 1rem;
}

.ReactModal__Overlay {
    z-index: 10;
}

.error {

    width: 100%;
    padding: 10px;
}

.services-iconsMain {
    width: 35px;
    height: 35px;
    color: #249fc0;
}

.services-icons {
    width: 30px;
    height: 30px;
    color: #249fc0;
}

.option-card {
    border-radius: 5px;
    margin: 10px;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 10px;
    width: 100%;
    transition: all .2s;
}

.option-card:hover {

    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

}

.option-card h2 {

    font-size: 1rem !important;

}

.option-card h3 {

    margin-top: 10px;
    font-size: .9rem !important;

}

.option-title {

    color: #33CCCC;
    font-size: 1.1rem;
}

.btn-warning {
    background: #ffc107 !important;
}

.option-input {
    background: transparent;
    border: none;
    width: 50px;
    text-align: center;
    padding: 0 4px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: none !important;
    box-sizing: border-box;
    color: #222;
    font-weight: bold;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.option-items {

    background: #f9f9f9;
    border-radius: 5px;
    padding: 0 5px;

    text-align: center;
    width: 100px;
    border: 1px solid #33CCCC;

}

.decrease {
    content: '-';
    font-size: 1.5rem;
    padding: 5px;
    cursor: pointer;
}

.increase {
    content: '+';
    font-size: 1.5rem;
    padding: 5px;
    cursor: pointer;
}

.offer-input {
    background: #f9f9f9;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    border: 1px solid #33CCCC;
}

*:focus {
    outline: none;

}

.apply_button {
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    margin-top: 7px;
    background: #33CCCC;
    border: 1px solid #fff;
}

.apply_button:hover {

    background: #2db0b0;
}

.request-resp {
    width: 100%;
    text-align: center;
    margin-top: 25px;
}

.request-resp h3 {
    color: #343434;
    font-size: 1.8rem;

}

.request-resp span {

    font-size: 1.4rem;

}

.request-resp p {
    margin-top: 20px;

    font-size: 1rem;

}

.request-resp a {
    cursor: pointer;
    font-size: 1.4rem;

}

.rotate-loading {

    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.MuiIconButton-root-42 {
    padding: 0 !important;
}

.MuiSvgIcon-root-1 {
    margin: 10px;
}

.top-menu-sight {
    background: url("../images/sight.png") no-repeat;
    background-size: contain;

}

.top-menu-route {
    background: url("../images/route.png") no-repeat;
    background-size: contain;

}

.top-menu-tour {
    background: url("../images/travel.png") no-repeat;
    background-size: contain;

}

.top-menu-ttd {
    background: url("../images/tent.png") no-repeat;
    background-size: contain;

}

.top-menu-item {
    width: 35px;
    height: 35px;
    padding-left: 50px;
    font-size: 1rem;
    padding-top: 6px;
    color: #888888;
    font-family: RobotoB;

}

.badge4-tr-2 {
    width: auto;
    font-family: 'helvetica';
    padding: 0px;
    margin: 0px;
    font-size: 12px;
    font-weight: normal;
    text-align: right;
    color: #9c9c9c;
}

.badge4-tr-2 .cc-tr-2 {
    border: none;
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: auto;
}

.top-menu-container {
    margin-top: 25px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 20px;
}

.top-menu-title {
    margin-bottom: 15px;
    color: #5a5a5a;
    font-size: 1.2rem;
    font-family: RobotoM;
}

.header__category {
    background: url('../images/sprite_category.png') no-repeat;
    margin-top: 13px;
    margin-bottom: 10px;
    background-size: 700% auto !important;
    width: 100%;
    height: 75px;
}

.menu-border {
    border: #33CCCC 1px solid;
    padding: 10px;
    border-radius: 5px;
}

.visa-types {
    margin-bottom: 50px;
}

.visa-types .visa {

    padding-left: 30px;
    margin-bottom: 10px;

}

.visa-online-form {

    margin: 0;
    width: 100%;

}

.visa-online-form .steps-container {
    border-radius: 5px;
    height: 10px;
    width: 100%;
    background: #e2e2e2;
    margin-bottom: 75px;
}

.visa-online-form .steps {
    border-radius: 5px;
    height: 10px;
    margin: 0;
    background: #33CCCC;
    transition: all .5s;
    position: absolute;
}

.visa-online-form .step-item {
    width: 35px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    height: 35px;
    border-radius: 50%;
    padding: 6px;
    top: -12.5px;
    position: absolute;
}

.visa-online-form .active {
    background: #33cccc;

}

.visa-online-form .red {
    background: tomato;

}

.visa-online-form .disable {
    background: #c1c1c1;

}

.visa-online-form .step-container {
    width: 20%;
    margin-top: -20px;
}

.visa-online-form .right {
    right: 0;
}

.visa-form {
    width: 100%;
}

.tickets-container {
    background: rgba(0, 0, 0, .5);
    height: 100%;
    position: absolute;
    z-index: 5;
    width: 100%;
    bottom: 0;
}

.tickets-container .ticket-items {
    position: absolute;
    z-index: 6;
    color: #fff;
    top: -10px;
    height: 60px;
}

.tickets-container .ticket-icon {
    top: -15px;
    left: calc(50% - 10px);
}

.tickets-container .ticket-title {
    position: absolute;
    font-family: RobotoB;
    font-size: 1rem;
    top: -20px;
    left: calc(50% + 5px);
}

.select-departure {
    margin-top: 220px;
}

.select-departure form {
    width: 100%;

}

.select-departure form input {
    width: 100%;
}

.select-departure .select2-container--default .select2-selection--single {
    border-radius: 5px;
    border: none;
    width: 100%;

}

.select-departure .SingleDatePicker, .SingleDatePickerInput__withBorder, .DateInput_input, .DateInput {
    width: 100% !important;
    border-radius: 5px !important;
}

.select-departure .DateInput_input {
    line-height: 20px !important;
    border-bottom: none !important;
    padding-left: 25px;
}

.CalendarDay__selected_span {
    background: #82e0aa;
}

.CalendarDay__selected {
    background: #fff;
    color: white;
}

.bus_needs h4 {
    color: #3cc;
}

.bus_needs p {
    font-family: RobotoL;
    font-weight: 100;

}

.CalendarDay__selected:hover {
    background: orange;
    color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: brown;
}

.ticket-container {
    background: #fff;
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, .05), 0 1px 5px 0 rgba(0, 0, 0, .15);
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 0;
    transition: all .5s ease-in-out;
}

.ticket-content {
    border-right: 2px dashed #e2dddd;
    padding: 20px;
}

.ticket-content:after {
    content: " ";
    width: 30px;
    height: 15px;
    background: #fff;
    position: absolute;
    right: -15px;
    bottom: -3px;
    z-index: 1;
    display: block;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-radius: 20px 20px 0 0;

}

.ticket-content:before {
    content: " ";
    width: 30px;
    height: 15px;
    background: #fff;
    position: absolute;
    right: -15px;
    margin-top: -22px;
    z-index: 1;
    display: block;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-radius: 0 0 20px 20px;

}

.company-name {
    font-family: Roboto;
    font-size: .8rem;
}

.company {

}

.destinations {
}

.bus_destination_item a {
    position: absolute;
    right: 0;
    border-radius: 5px;
    padding: 2px 5px 2px 5px;
    border: 1px solid #4e4e4e;
    color: #4e4e4e;
    margin-bottom: 0;
    font-weight: 700;
    -webkit-box-flex: 0;
    background: #fff;
    -ms-flex: 0 0 84px;
    flex: 0 0 84px;
}

.bus_destination_item a:hover {
    color: #3cc;
    border: 1px solid #3cc;
}

.bus_destination_item {
    padding: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.bus_destination_item .dashed {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 126px;
    flex: 0 0 126px;
    margin-left: 12px;
}

@media screen and (max-width: 740px) {
    .bus_destination_item .dashed {

        -ms-flex: 0 0 50px;
        flex: 0 0 50px;
    }

}

@media screen and (max-width: 995px) {
    .bus-header {
        display: none;
    }

}

.bus_destination_item .dashed span {
    border-bottom: 1px dashed #555555;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
}

.bus_destination_item .dashed span:first-child {
    width: 7px;
    position: absolute;
    height: 7px;
    border: 1px solid #353535;
    border-radius: 50%;
    background-color: #fff;
}

.bus_destination_item span {
    margin-bottom: 0;
    font-size: 16px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
}

.busType {
    color: #595959;
}

.busType span:first-child {
    padding: 2px 30px;
    font-family: RobotoM;
    display: -webkit-inline-flex;
    font-size: .8rem;
    width: 135px;
}

.busType span {
    font-family: Roboto;

    font-size: .8rem;
}

.time {
    font-family: RobotoM !important;
    font-size: 1rem !important;
    color: #000;
}

.TicketHour {
    width: 100%;
    display: block;
}

.origin-destination span {
    padding: 5px 20px 5px 30px;
    font-family: RobotoM;
    font-size: .8rem;
}

.origin-destination {
    display: block;
    margin-top: 15px;
}

.origin-destination .bus {
    margin-left: 25px;
    margin-right: 25px;
}

.origin-destination .bus::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    background-size: 30px;
    margin-left: -35px;
    top: 93px;
    margin-top: 10px;
}

.origin-destination .bus::after {

    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background-size: 20px;
    top: 93px;
    margin-top: 10px;
    background-image: url(../../../assets/frontend2/images/pin.png);
    background-repeat: no-repeat;
    background-position: center;
}

.Ticket-Price {

    color: #33cccc;
    width: 100%;
    font-family: RobotoB;
    text-align: center;
    padding: 25px;
    font-size: 1.5rem;
}

.Ticket-Price .Ticket-Price-dis {

    color: tomato;
    width: 100%;
    font-family: RobotoM;
    text-align: center;
    font-size: 1.3rem;
}

.Ticket-Button {
    padding: 10px;
    text-align: center;
    font-size: 1rem;
    box-shadow: 0 0 15px rbga(0, 0, 0, 0.8);
    border-radius: 5px;
    font-family: RobotoB;
    background: -webkit-linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    background: -moz-linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    background: -ms-linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    background: -o-linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    background: linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    cursor: pointer;
}

.Ticket-Button span {
    padding: 15px;
    color: #fff;

}

.Ticket-book {
    margin-left: 25px;
}

.Ticket-book .disabled {
    padding: 10px;
    text-align: center;
    font-size: 1rem;
    box-shadow: 0 0 15px rbga(0, 0, 0, 0.8);
    border-radius: 5px;
    font-family: RobotoB;
    color: #fff;
    background: #a0a0a0;
    cursor: not-allowed;
}

.available-seats {
    margin-top: 15px;
    text-align: center;
    font-family: RobotoL;
    color: #2c2b2b;
}

.ticket-results {
    background: #fff;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 1rem;
    padding: 20px;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    font-family: RobotoL;
}

.ticket-filter {
    background: #fff;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 1rem;
    padding: 20px;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    font-family: RobotoL;
}

.input-range {
    font-family: RobotoL !important;
    height: 1rem;
    position: relative;
    top: 24px;
    width: 90% !important;
    margin-left: 10px !important;
    margin-bottom: 40px;
}

.input-range__track--active {
    background: -webkit-linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    background: -moz-linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    background: -ms-linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    background: -o-linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
    background: linear-gradient(left, #33CCCC 0%, #3e81d2 100%) !important;
}

.input-range__slider {
    webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #2ecbcb !important;
    border: 1px solid #2ecbcb !important;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    width: 1rem;
}

.more-info {
    width: 100%;
    text-align: center;
    cursor: pointer;
    color: #fff;
    background: #3e81d2;
    border-radius: 5px;
    padding: 5px;
    font-family: RobotoM;
    margin-top: 20px;
    font-size: .8rem;
    transition: all .2s;
}

.more-info:hover {
    box-shadow: 0 0 15px #a3a3a3;
}

.busSeats {
    display: flow-root;
    margin: 5px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #000;
    width: 100%;
    box-shadow: 0px 0 15px #cacaca;

}

.busSeats .seats {
    border-radius: 5px;
    float: left;
    width: calc(80%);
    padding: 10px;
    margin-left: 5%;
}

.busSeats .chair {
    border-radius: 5px;
    float: left;
    border: 1px solid #33CCCC;
    padding: 10px !important;
    width: 100%;
    color: #33CCCC;
    cursor: pointer;
    text-align: center;
}

.busSeats .booked {
    border-radius: 5px;
    float: left;
    padding: 6px;
    background-color: #555555;
    color: #fff !important;
    width: 100%;
    height: 40px;
}

.busSeats .selected {
    border-radius: 5px;
    float: left;
    padding: 6px;
    background-color: #32cc91;
    color: #fff !important;
    width: 100%;
    height: 40px;
}

.busSeats .driver {
    float: left;
    height: 270px;
    position: relative;
    border-right: 1px solid #ababab;
    width: calc(15%);
    padding: 10px;
}

.guide {
    display: flex;
    text-align: center;
}

.guide .booked {
    border-radius: 5px;
    float: left;
    padding: 6px;
    background-color: #555;
    color: #fff !important;
    width: 100px;
    margin: 5px;

}

.guide .chair {
    border-radius: 5px;
    float: left;
    border: 1px solid #33CCCC;
    color: #33CCCC;
    height: 42px;
    cursor: pointer;
    text-align: center;
    padding: 6px;
    width: 100px;
    margin: 5px;
}

.ReactModal__Overlay {
    z-index: 100;
    background: rgba(0, 0, 0, 0.4) !important;
}

.ticket-buy {
    background: #fff;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    transition: all .5s ease-in-out;
}

.ticket-buy .header {

    padding: 5px;
    width: 100%;
    margin-bottom: 15px;
    border-bottom: 1px solid #e3e3e3;

}

.ticket-buy .header.bus_ticket {

    padding-right: 5px;
    background: url("../images/svg/Bus_Ticket.svg") no-repeat;
    background-size: 20px;

}

.ticket-buy .header.seat_ticket {

    padding-right: 5px;
    background: url("../images/svg/seat.svg") no-repeat;
    background-size: 20px;

}

.ticket-buy .header.passenger_info {

    padding-right: 5px;
    background: url("../images/svg/passenger.svg") no-repeat;
    background-size: 20px;

}

.ticket-buy .inform {

    font-size: 1rem;
    font-family: RobotoL;
    padding: 5px;
    margin-bottom: 15px;

}

.ticket-buy .header h4 {
    font-size: 1rem;
    font-family: RobotoL;
    margin-left: 20px;
}

.ticket-buy input {

    padding: 10px 10px 10px 40px;
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #3ccccc;
    transition: .5s;
    border-radius: 5px;
}

.ticket-buy input.name {
    background: url("../images/svg/name_info.svg") no-repeat;
    background-size: 25px;
    background-position: 5px 4px;
}

.ticket-buy input.passport {
    background: url("../images/svg/passport.svg") no-repeat;
    background-size: 25px;
    background-position: 5px 4px;
}

.ticket-buy input.email {
    background: url("../images/svg/email.svg") no-repeat;
    background-size: 25px;
    background-position: 5px 4px;
}

.ticket-buy input.mobile {
    background: url("../images/svg/mobile.svg") no-repeat;
    background-size: 25px;
    background-position: 5px 4px;
}

.ticket-buy .select2-selection__rendered {
    border-radius: 5px;
    cursor: pointer;
    background: #fff;
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #3ccccc;
    transition: .5s;
}

.ticket-buy label {
    font-family: RobotoB;
    font-size: .9rem;
    color: #6c6c6c;
    margin-bottom: 5px;

}

.ticket-buy select {
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    background: #fff;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #33CCCC;
    transition: .5s;
}

.ticket-buy button {
    position: absolute;
    width: 100px;
    left: 50%;
    top: 70px;
}

.ticket-buy input:focus, .ticket-buy select:focus {
    border: 1px solid #33CCCC;

}

.Change-Ticket-Button {
    padding: 10px;
    text-align: center;
    font-size: 1rem;
    border-radius: 5px;
    font-family: RobotoB;
    border: 1px solid #33CCCC;
    color: #33CCCC;
    cursor: pointer;

}

#destination .select2-container {
    border: 1px solid #dbdbdb !important;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
}

.ticket-container {
}

.ticket-content {
    margin-left: 0 !important;
}

.Ticket-book {
    margin-left: 0 !important;
    padding-left: 30px;
}

.chairCntainer {
    height: 50px;
    display: flex;
}

.wheel {
    position: absolute;
    width: 50px;
    bottom: 40px;
    left: 60px;
    padding: 5px 0px 5px 40px;
    height: 50px;
    display: block;
    transform: rotate(-90deg);
}

@media screen and (max-width: 780px) {

    .bus {
        display: none !important;
    }

    .ticket-content {
        border-bottom: 2px dashed #e2dddd;
        border-right: none;
    }

    .ticket-content:after {
        content: " ";
        width: 15px;
        height: 30px;
        background: #fff;
        position: absolute;
        right: -3px;
        bottom: -15px;
        z-index: 1;
        display: block;
        border-left: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;
        border-radius: 20px 0 0 20px;

    }

    .busSeats {
        display: flow-root;
        margin: 5px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 15px;
        border: 1px solid #000;
        width: 100%;
        box-shadow: 0px 0 15px #cacaca;
    }

    .chairCntainer {
        height: auto;
        display: table;
        width: 20%;
        float: right;
    }

    .wheel {

        transform: rotate(0deg);
        position: static;
    }

    .chair {
        margin-bottom: 5px;
    }

    .booked {
        margin-bottom: 5px;
    }

    .guide .booked, .guide .chair {

        width: 35px;
    }

    .guide {
        font-size: 0.6rem;
    }

    .guide div {
        padding: 10px;
    }

    .busSeats .driver {
        float: left;
        width: 100%;
        position: static;
        border-right: 0;
        border-bottom: 1px solid #ababab;
        height: auto;
        padding: 10px;
    }

    .busSeats .seats {
        border-radius: 5px;
        float: left;
        height: auto;
        width: 100%;
        display: flex;
        direction: rtl;
        padding: 10px;
        margin-left: 0;
    }

    .ReactModal__Content {
        max-height: 600px !important;
        overflow: auto !important;
    }

    .ticket-content:before {
        content: " ";
        width: 15px;
        height: 30px;
        background: #fff;
        position: absolute;
        left: -3px;
        bottom: -15px;
        z-index: 1;
        display: block;
        border-left: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;
        border-radius: 0 20px 20px 0;

    }

}

.alert-custom {
    position: fixed;
    top: 200px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-radius-topleft: 5px;
    border-top-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-left-radius: 5px;
    width: 15%;
    text-align: justify;
    -webkit-box-shadow: 0px 0 15px #cacaca;
    -moz-box-shadow: 0px 0 15px #cacaca;
    box-shadow: 0px 0 15px #cacaca;
    background: #fff;
    z-index: 200;
    transition: all 1s;
}

.alert-custom input {
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #cbcbcb;
    transition: .5s;
}

.alert-custom-header {
    padding: 7px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-radius-topleft: 5px;
    border-top-left-radius: 5px;
    background: #3cc;
}

.alert-custom-header h5 {
    color: #fff;
    text-align: center;
}

.alert-custom-body {
    padding: 7px;
}

.submit-btn {
    border-radius: 5px;
    width: 100%;
    background: #fe971e;
    text-align: center;
    color: #fff;
    cursor: pointer;
    padding: 7px;
}

.cross-alert {
    background-image: url(../images/svg/cancel.svg);
    width: 20px;
    height: 20px;
    background-size: contain;
    cursor: pointer;
    position: absolute;

}

.bus-header {
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 75px;
    padding: 10px;
    background: #555555;
}

.bus-header .bus-header-item span {
    position: relative;
    width: 50px;
    padding: 5px 20px 5px 50px;;
    height: 50px;
    color: #fff;
    font-size: 17px;

}

.bus-anim {
    background: url("../images/svg/without bus.svg") 0% 0% / contain no-repeat;
    width: 100%;
    height: 400px;
}

.bus-anim-bus {
    background: url("../images/svg/Two Bus.svg") no-repeat;
    width: 50px;
    background-position: -175px 0px;
    background-size: 395px;
    position: absolute;
    left: 174px;
    top: -15px;
    height: 400px;

}

.bus-anim:hover > .bus-anim-bus {

    -webkit-animation: spinBus 5s linear infinite;
    animation: spinBus 5s linear infinite;
}

@-webkit-keyframes spinBus {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinBus {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.bus-header-item {

    padding: 10px;

}

.bus-header-item-destination {
    padding: 10px;
    text-align: center;
}

.bus-header-item-destination .des:before, .bus-header-item-destination .des:after {
    top: -12px
}

.bus-header-item-destination span {
    position: relative;
    color: #fff;
    font-size: 17px;
}

.questions p {
    font-family: RobotoL;
    font-weight: 100;
}